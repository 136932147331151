<template>
  <Navbar1/>
  <div class="content">
    <router-view/>
  </div>
  </template>

<script>
import Navbar1 from './components/Navbar1.vue'

export default {
  components:{ Navbar1 }
}



</script>

<style>
.content{
  width:100%;
  margin: 0%;
}

#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;

}
</style>
