<template>
     
    <!-- <div v-for="gallery in galleries" :key="gallery.id"> -->
          
        <!-- <router-link :to="{name: 'GalleryDetails', params: { id: gallery.id}}" >
            <div class="single">
                <div class="thumbnail">              
                    <img :src="gallery.pics[0].coverUrl" alt="coverimage">
                </div>
                <div class="info">
                    <h3>{{ gallery.title }}</h3>
                    <p>Created by {{ gallery.userName }} </p>
                </div>
                <div class="pictureNumber">
                    <p>{{ gallery.pics.length }}</p>
                </div>
            </div>    
        </router-link> -->
         <!-- <div class="col-12 col-sm-6 col-md-4 col-lg-3  mb-5 d-flex align-items-stretch "   -->








             
            <div class="row ">
                    <div class="col-6 col-sm-6 col-md-3 col-lg-3 mb-5  " 
                    v-for="gallery in galleries" :key="gallery.id">

                       
                  
                        
                            <div class="card text-center  h-100" >
                                <router-link :to="{name: 'GalleryDetails', params: { id: gallery.id}}" >
                                <div class="card-body">
                                    <h5 class="card-title">{{ gallery.title }}</h5>
                                     <p class="card-text"><small>{{gallery.description}}</small></p>
                                     <div v-if="gallery.pics.length>0" class="thumbnail">
                                        <img  class="rounded-circle shadow-1-strong"  width="75" height="75" 
                                        :src="gallery.pics[0].thumbnailUrl"
                                         alt="coverimage">
                                     </div>
                                      <div v-if="!gallery.pics.length>0" class="thumbnail">
                                       <p>Images not yet added to this gallery!</p>
                                       <!-- <video autoplay muted loop>
                                        <source src="/videos/notfound1.webm" type="video/webm">
                                        No image or videos found.
                                        </video> -->
                                     </div>
                                </div>
                                </router-link>
                                
                                 <router-link :to="{name: 'GalleryDetails', params: { id: gallery.id}}" >
                                     <button type="button" class="btn btn-light">Launch Gallery!</button>
                                 </router-link>
                                <div class="card-footer text-muted">
                                    
                                    <small> By: {{ gallery.userName}}    </small> <br>

                                    <small> 
                                       <img src="/images/eye.png" style="width:35px" />
                                        {{ gallery.views}}    </small> 

                                        &nbsp                                          
                                    <small> 
                                       <img src="/images/heart.png" style="width:25px" />
                                        {{ gallery.totallikes}}    </small> 

                                          
                                    <!-- <button id="followbutton" class="secondarybutton" @click="follow">Follow</button> -->
                                    <!-- <span style="right: 5px; position: absolute;"> <a href="#" class="card-link "><i class="far fa-heart "></i></a></span> -->
                                    
                                </div>
                                
                            </div>
                         
                    </div>
            </div>
       




    <!-- </div> -->
</template>

<script>

  import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardImg, MDBBtn, mdbRipple } from "mdb-vue-ui-kit";



export default {
        props: ["galleries"],
        components: {
        MDBCard,
        MDBCardBody,
        MDBCardTitle,
        MDBCardText,
        MDBCardImg,
        MDBBtn
        },
        directives: {
        mdbRipple
        },
        setup(){
                function follow(){
                    alert('follow');
                    var thisbtn = document.getElementById("followbutton");
                    
                    thisbtn.innerHTML = 'Following!';

                }

            return{follow}
        }

       
    
 }

    
    

</script>

<style scoped>


.thumbnail{
  /* max-width: 200px;
  max-height: 200px;
  position: relative; */
  overflow: hidden;
  max-height: 150px;
  /* box-shadow: 10px 10px; */
  /* border: 5px solid #da0f41; */
  /* border-radius: 50%; */
  /* clip-path: circle(50%); */
  
}

.secondarybutton{
    background:#ebebeb !important;
}

.boxshadow{

    box-shadow: 10px 10px #da0f41;
}

/* .clearbtn {
    background:black;
    color:white;
    font-family: 'Nunito', sans-serif !important;
    border-radius: 8px;
    border: 0;
    padding: 2px 8px !important;
    font-weight: 600;
    cursor: pointer;
    display: inline-block;
    
} */

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

button, .btn {
    background:#da0f41;
    color:white;
    font-family: 'Nunito', sans-serif !important;
    border-radius: 4px;
    border: 0;
    padding: 2px 8px !important;
    font-weight: 600;
    cursor: pointer;
    display: inline-block;
    
}

button:hover, .btn:hover {
    background: #ebebeb;
    color: white;
}

/* .thumbnail img{
 position: absolute;
  display: block;
  top: 0;
  left: 0;
  
} */

/* .thumbnail{
    
    height: 100px;
    overflow: hidden;
    border-radius: 2px;
    
    
} */

/* .single{
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    background: white;
    margin: 16px 0;
    transition: all ease 0.2s;
}

.single:hover{
    box-shadow: 1px 2px 3px rbga(50, 50, 50, 0.05);
    transform: scale(1.02);
    transition: all ease 0.2s;
}

.thumbnail{
    max-width: 100px;
    height: 100px;
    overflow: hidden;
    border-radius: 10px;
    
    
}

img{
   max-width: 150%;
   max-height: 150%; 
   display: block;
}

.info{
    margin: 0 30px;
}

.pictureNumber{
    margin-left: auto;
} */
</style>