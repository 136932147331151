<template>
  <div class="home container" >
    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- <router-link :to=" {name: 'CreateGallery'} " class="btn bg-white">Create a new Gallery</router-link> -->
    
      <div class="textsection">
        <br>
          <!-- <h1> <span style="color:#da0f41;">Instant.Gallery</span></h1>  -->
          <img style="width: 100px; float:left; margin-right: 13px" src="/images/logo.webp" alt="instant gallery logo">

          <h3> Your photos in the metaverse!</h3>
          <p>No downloads or apps needed. <br>Unlimited gallery creation is free. It couldn't be easier and takes just a few seconds to get started.</p>

          <!-- <p> Our creators instantly share and discover photos that can be viewed on mobile, desktop and, if you have a Virtual Reality headset, you can walk around your own art gallery!! </p>

              <p>Explore the top 10 most popular galleries below, or sign up for a free account and start creating your own. Upload some pictures, decide who you want to share your gallery with - it couldn't be easier and takes just a few seconds to get started.</p> -->

            <div class="col-xl-8" style="display: block; margin: auto;">
                        <div class="h2 fs-1 text-white mb-4" ><br>
                              <!-- <iframe width="80%" src="https://www.youtube.com/embed/zbmyVrAmYoU?autoplay=1&mute=1"></iframe> -->
                              <iframe style="aspect-ratio: 16 / 9; width: 100%; " src="https://www.youtube.com/embed/zbmyVrAmYoU?autoplay=1&mute=1"></iframe>
                        </div>
                        <!-- <div class="fs-1 text-white mb-4">dino8932 (App Store review)</div> -->
                    </div>
  

  
       </div>

             <!-- <aside class="text-center bg-gradient-primary-to-secondary">
            <div class="container px-5">
                <div class="row gx-5 justify-content-center">
                    <div class="col-xl-8">
                        <div class="h2 fs-1 text-white mb-4"><br>
                              <iframe style="aspect-ratio: 16 / 9; width: 100%" src="https://www.youtube.com/embed/zbmyVrAmYoU?autoplay=1&mute=1"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </aside> -->
    
      <!-- The galleries you create can be shared easily - no account needed to view your collections. -->
          <!-- <div class="ui icon input" style="width: 100%">
              <input type="text" placeholder="Search..." v-model="searchQuery" />
              <i class="search icon"></i>
          </div> -->

          <!-- <div
              class="card ui fluid"
              v-for="product in searchedProducts"
              :key="product.id"
              style="margin: 0"
            >
              ...
            </div> -->

            <div v-if="galleries && user"><br>
            <h2>Galleries You Created</h2>
                <ListView v-if="usergalleries" :galleries="usergalleries" />
            <hr>
          </div>
    
    <br><br><h2>Popular Galleries</h2>
    <div v-if="error" class="error">Could not fetch the data</div>
    <div v-if="galleries">
       <ListView :galleries="galleries" />
           <router-link :to="{ name: 'Popular' }" >
          <button class=" btn btn-sm floatingbutton responsiveText" >Show More <strong>Popular</strong> Galleries</button>
        </router-link>

      
    </div><hr>

    

       
      
         <!-- <div v-if="!user">
            <p>Signup instantly for a free acount and get creating!!</p>
        </div>
          <div v-if="user && !usergalleries">
            <p>User found, but no galleries</p>
        </div> -->
        

     <br><h2>New Galleries</h2>
    <div v-if="error" class="error">Could not fetch the data</div>
    <div v-if="newgalleries">
       <ListView :galleries="newgalleries" />
          <router-link :to="{ name: 'New' }" >
          <button class=" btn btn-sm floatingbutton responsiveText" >Show More <b>New</b> Galleries</button>
        </router-link>
        <br><hr>
      
    </div>

     <!-- <br><br><h2>Popular Creators</h2>
     <div v-if="documents">
      <PeopleView :people="documents"/>
    </div> -->


  </div>
</template>

<script>
import getUser from '@/composables/getUser'

import ListView from '../components/ListView.vue'
import getCollection from '../composables/getCollection'
import AddImage from '../components/AddImage.vue'

import useDocument from '@/composables/useDocument'

// import { ref } from 'vue'
// import { computed } from '@vue/reactivity'


export default {
  name: 'Home',
  components: {ListView, AddImage},
  setup() {
    // const { error, documents } = getCollection('galleries')
      const { user } = getUser()

      const { incrementHomePageView } = useDocument('views', 'homepage')

      const addView = async (data) => {
        await incrementHomePageView()
      }

      addView();

      if (user.value != null){
           var { documents: usergalleries } = getCollection(
            'galleries',
            ['userId', '==', user.value.uid],
            'thisusergalleries'
        )
      }
            //console.log('test user')
            //console.log(user.value.uid)


      const { documents: galleries } = getCollection(
            'galleries',
            ['sharingOptions', '==', 'discoverable'],
            'popular'
        )


      // const searchQuery = ref("");

      // const searchedProducts = computed(() => {
      //     return galleries.value.filter((product) => {
      //       return (
      //         product.title
      //           .toLowerCase()
      //           .indexOf(searchQuery.value.toLowerCase()) != -1
      //       );
      //     });
      // });
     


         const { documents: newgalleries } = getCollection(
            'galleries',
            ['sharingOptions', '==', 'discoverable'],
            'new'
        )
    // console.log('home')
    // console.log(documents)

    return {galleries, newgalleries, usergalleries, user}
  }
}
</script>

<style scoped >
  button{
   margin: 0px 2px !important;
}
</style>
