<template>
   
    <!-- <img src="/images/stone.jpg" alt="" class="test"> -->
    <!-- <div v-if="error" class="error"> {{error}}</div>     -->

  
  

    <div v-if="gallery" class="noscroll">

        <!-- if owner
        Show HTML. Show title and add image button -->
             <!-- <div class="">
                 <div class="row">
                    <div class="col-6 ">{{gallery.title}}</div>
                    <div class="col-6 ">Created by {{gallery.userName}}</div>
                </div>
                  
                       <AddImage v-if="ownership" :gallery="gallery" />
            </div>  -->

              <!-- <h2> {{gallery.title}}</h2> -->
                    <!-- <p class="username">Created by {{gallery.userName}}</p> -->
                    <!-- <p class="description">{{gallery.description}}</p> -->
                    <!-- <button v-if="ownership" @click='handleDelete'>Delete Gallery</button>             -->
        
            <!-- back button -->
            
            
        <!-- 
        <p></p> -->
        <!-- <p>{{gallery.userName}}</p> -->


        

        
        <!-- <div class="mainContainer" > -->
             <div class="acontainer noscroll" id="aframescene">
               <!-- <div class="container-fluid acontainer" id="aframescene"> -->

                 <AddImage v-if="ownership && !editMode" :gallery="gallery" />

                 

                  <div v-if="ownership"  class="" style="z-index:1001; ">               
                            <img id="editGalleryButton" @click="handleGalleryEdit" src="/images/editgallery.png" alt=""> 
                            
                    </div>
                    <!-- fab fa-twitter-square -->
                    <!-- <i class="fa-solid fa-gallery-thumbnails"></i> -->
                  
                 <button id="deleteGalleryButton" v-if="editMode" @click="handleDelete">Delete Gallery</button>
            <!-- <div id="backButton"  class="navbuttons">
                <router-link to="/"><i style="color:white" class="fas fa-home fa-2x" ></i><span style="color:white">home</span>    </router-link>
                            
            </div>
             <div id="shareButton" class="navbuttons">               
                <i style="color:white;"  class="fab fa-slideshare fa-2x"></i><span style="color:white; ">share</span>   
            </div> -->

                <!-- AFRAME***********************************!!! -->
                <!-- vr-mode-ui="enabled: false" -->
                <div v-if="pinprotected" id="pinpad"><br><br>

                    <form @submit.prevent="handlePinCheck">
                      <p>This gallery is pin protected.</p>
                      <input type="password" name="pin" minlength="4" maxlength="4" placeholder="Enter 4 digit pin here" v-model="pin" >
                      <input type="submit" value="Enter"  >
                  </form>

                </div>

                 <!-- <a v-show="!mobileNotOwner || !mobileOwner" id="myEnterVRButton" href="#" >
                  <img id="entervrimage" src="/images/entervr1.png"  >
                </a> -->

               <a-scene v-if="!pinprotected || unlocked" id="myaframescene" embedded setup-scene loading-screen="dotsColor: #f6f6ef; backgroundColor: black;"
                physics="debug: false; gravity: 0; restitution: 0.9;" 
                 renderer=" sortObjects: true;  "
                class="aframeDiv noscroll" background="color: #C1C8E4"
                joystick vr-mode-ui="enabled: true; " 
                cursor="rayOrigin: mouse" 
                fog="type: linear; color: #C1C8E4; near: 100; far: 250"
                

                >
                
                <!-- :fog="scenefog" -->
                <!-- fog="type: linear; color: #000; far: 50" -->

          

                <!-- vr-mode-ui="enabled: true; enterVRButton: #myEnterVRButton;  -->
                  <!-- renderer="antialias: true; logarithmicDepthBuffer: true; colorManagement: true; sortObjects: true;" -->
                  <!-- logarithmicDepthBuffer: true; -->

                  <!-- <a-box v-if="editMode" @click="handleDelete" position="0 2 -5"></a-box> -->

               
              
                  <!-- lisbon, ny hotel, blade runner -->
            <!-- TODO ************************************* -->
                  <!-- todo
                    Delete
                    resize - thumbnails and images
                    nsfw filter
                    classification
                    mono color, need lobby can choose which gallery per user
                    vr mode - images twie the size
                    mobile code, swipe left, swipe right, tap to get best view
                    YOUR GALLERY, POPULAR, NEW FROM PEOPLE YOU FOLLOW, INDEPENDENT ARTIST OF THE WEEK/NEWS
                    s-corp
                    stripe setup subsription model
                    social media - follow topics, users, 
                      for someone you follow, go to lobby, selet which gallery from wall panel (thumbnails)
                    LAUNCH!!!!-->

                    <!-- boxing app - flutter, iphone and android
                    ios - augemented reality app
                    vr ftness game 
                    learning tools -->
            <!-- END TODO -->
                    

              
                    <!-- art gallery1 grey, red accent -->
                    <!-- instant.gallery art gallery grey: #f6f6ef
                    artgallery border:white
                    art gallery red miniborder: #c9011a -->

                    <!-- art gallery2 green, grey accent -->
                    <!-- art gallery grey: #858885
                    art gallery grey: #655f56 -->

                      <!-- art gallery3 pale red, dark grey accent -->
                    <!-- art gallery grey: #d96e74
                    art gallery grey: #161415 -->

                     <!-- art gallery4 bright red, wood accent -->
                    <!-- art gallery grey: #fc4c34
                    art gallery grey: #975e11 -->

                    <!-- from canva -->
                     <!-- art gallery 1: #4ABDAC
                    art gallery 2: #F78733 
                    art gallery 3: #FC4A1A -->

                    <!--NICE OXBLOOD art gallery 1: #76323F
                    art gallery 2: #565656 
                    art gallery 3: #FC4A1A -->

                     <!-- ART HISTORY art gallery 1: #0375B4
                    art gallery 2: #FFCE00 
                    art gallery 3: #FC4A1A -->

                     <!-- hacker news art gallery 1: #f6f6ef
                    hacker news art gallery 2: #ff6600 
                    hacker news art gallery 3: # -->
                    

                    
                    <a-assets>

                        <a-mixin id="banner" material="roughness: 0.6; metalness:0.2; color:#da0f41;"></a-mixin>

                      

                         <a-mixin id="metalwall" material="roughness: 0.8; metalness:1; color:#fff"  opacity="1"></a-mixin>  

                           <a-mixin id="border" v-if="instant" material="roughness: 0.8; metalness:0.1;"  opacity="1"></a-mixin>
                            <a-mixin id="border" v-if="!instant" material="roughness: 0.8; metalness:0.1; color:#232023"  opacity="1"></a-mixin>

                           <a-mixin id="miniborder" material="roughness: 0.8; metalness:0.15; color:#da0f41"  opacity="1"></a-mixin>

                            <a-mixin id="woodborder" material="roughness: 0.9; metalness:0.4; color:#fff"  opacity="1"></a-mixin>

                           <a-mixin id="topborder" material="roughness: 0.6; metalness:0.5; color:#fff"  opacity="1"></a-mixin>
                           <a-mixin id="topminiborder" material="roughness: 0.6; metalness:0.5; color:#fff"  opacity="1"></a-mixin>

                           <!-- <a-mixin id="frame" material="roughness: 1; metalness:0.5; color:#fff"  opacity="1"></a-mixin> -->
                           <audio v-if="ornate || wedding" id="level1" :src="classicalStartingSong" ></audio>
                            <audio v-if="birthday" id="level1" :src="birthdayStartingSong" ></audio>
                            <audio v-if="neon" id="level1" :src="neonStartingSong" ></audio>
                            <audio v-if="!neon || !birthday" id="level1" :src="startingSong" ></audio>
                            
                            

                           
                           

                            <!-- <a-asset-item id="robot" src="/objects/robot/scene.gltf"></a-asset-item>   -->

                           <!-- <img id="beam" src="/images/beam.jpg"> -->
                           <!-- <img id="wood1p" src="/images/woodlp.jpg">
                           <img id="wood1pnormal" src="/images/woodlpnormal.jpg"> -->
                             <!-- <img id="stone" src="/images/stone.jpg">
                           <img id="stonenormal" src="/images/stonenormal.jpg"> -->

                           <!-- <img id="rwood" src="/images/wood3.png">
                          <img id="rwoodnormal" src="/images/wood3normal.jpg"> -->

                           <!-- <img id="nwood" src="/images/wood1.jpg">
                          <img id="nwoodnormal" src="/images/wood1normal.jpg"> -->

                          <!-- <img id="window" src="/images/window.png">
                          <img id="windownormal" src="/images/windownormal.jpg"> -->

                          <img id="window" src="/images/floor1.webp">
                          <img id="windownormal" src="/images/floor1n.webp">

                           <img id="close" src="/images/x.webp">

                           <!-- <img id="marble" src="/images/c.jpg">
                           <img id="marblenormal" src="/images/cn.jpg"> -->

                           <img id="concreten" src="/images/infobordern.webp">

                      

                            <img v-if="instant || moody || ornate || birthday || wedding" id="marble" src="/images/wood1.webp">
                           <img v-if="instant || moody || ornate || birthday || wedding" id="marblenormal" src="/images/wood1normal.webp">

                          <!-- <img v-if="summer " id="marble" src="/images/sand.jpg">
                           <img v-if="summer" id="marblenormal" src="/images/sandn.jpg"> -->

                           <img v-if="neon" id="marble" src="/images/80sfloor.webp">
                           <img v-if="neon" id="marblenormal" src="/images/80sfloorn.webp">

                           

                          <!-- works well, 'ornate marble'  -->
                            <!-- <img id="marblep" src="/images/marblep.jpg">
                           <img id="marblepn" src="/images/marblepn.jpg"> -->

                           <!-- <img v-if="summer" id="wallmaterial" src="/images/summerwall.jpg">
                           <img v-if="summer" id="wallmaterialn" src="/images/summerwalln.jpg"> -->

                            <img v-if="instant || moody" id="wallmaterial" src="/images/window1.webp">
                           <img v-if="instant || moody" id="wallmaterialn" src="/images/window1n.webp">

                                  <img v-if="neon" id="wallmaterial" src="/images/80swall.webp">
                           <img v-if="neon" id="wallmaterialn" src="/images/80swalln.webp">

                           <img v-if="ornate" id="wallmaterial" src="/images/ornate.webp">
                           <img v-if="ornate" id="wallmaterialn" src="/images/ornaten.webp">

                           <img v-if="birthday" id="wallmaterial" src="/images/window1.webp">
                           <img v-if="birthday" id="wallmaterialn" src="/images/balloonwalln.webp">

                           <img v-if="wedding" id="wallmaterial" src="/images/window1.png">
                           <img v-if="wedding" id="wallmaterialn" src="/images/weddingwalln.webp">

                           <!-- <img id="glassroof" src="/images/glassroof.jpg">
                           <img  id="glassroofn" src="/images/glassroofn.jpg"> -->

                           <img v-if="ornate" id="picframe" src="/images/ornateframe.webp">
                           <img v-if="ornate" id="picframen" src="/images/ornateframen.webp">

                           <img v-if="ornate" id="picframep" src="/images/ornatep.webp">
                           <img v-if="ornate" id="picframepn" src="/images/ornatepn.webp">

                             <img v-if="birthday" id="picframe" src="/images/balloonframe.webp">
                           <img v-if="birthday" id="picframen" src="/images/balloonframen.webp">
                           <img v-if="birthday" id="picframeh" src="/images/balloonframeh.webp">
                           <img v-if="birthday" id="picframehn" src="/images/balloonframehn.webp">

                           <img v-if="wedding" id="picframe" src="/images/weddingframe1.webp">
                           <img v-if="wedding" id="picframen" src="/images/weddingframe1n.webp">
                              <img v-if="wedding" id="picframe2" src="/images/weddingframe2.webp">
                           <img v-if="wedding" id="picframe2n" src="/images/weddingframe2n.webp">

                                <!-- <img v-if="neon" id="neonfilter" src="/images/sparkle.webp">
                           <img v-if="neon" id="neonfiltern" src="/images/sparklen.webp"> -->

                                <a-mixin v-if="instant" id="wall" material="roughness: 0.9; metalness:0.1; color:#fff"  opacity="1"
                                ></a-mixin>      
                                <a-mixin v-if="!instant" id="wall" material="roughness: 0.9; metalness:0.1; color:#000"  opacity="1"></a-mixin>

             

                           <!-- <img id="bordernormal" src="/images/bordern.jpg"> -->

                           <!-- <img id="link" src="/images/link.png"> -->
                           <img id="linkbox" src="/images/linkbox.webp">

                           <img id="fave" src="/images/heart.png">
                           <img id="faveselected" src="/images/heartred.png">

                            <img id="faveboxempty" src="/images/faveboxempty.webp">
                           <img id="faveboxfull" src="/images/faveboxfull.webp">

                           <img id="80sfilter" src="/images/80sfilter.png">

                           <img id="logo" src="/images/logo-small.png">

                           <img id="trim" src="/images/trim.webp">
                           <img id="trimn" src="/images/trimn.webp">
                           



                           
                          
                           <!-- <audio id="lasersound" src="/sounds/laser.mp3" preload="auto" ></audio> -->
                            <audio id="metalcrash" crossorigin="anonymous" src="/sounds/metalcrash.mp3" preload="auto" ></audio>
                        
                          <!-- <a-asset-item id="laser" src="/objects/laser/Pistol_1.gltf"></a-asset-item>  -->
                          
                                          
                        <img  v-for="(pic, index) in gallery.pics" :key="pic.id" :id="'image' + index" :src="pic.resizedUrl" crossorigin="anonymous">
                        <!-- <video id="intro" autoplay loop="true" src="/videos/intro.mp4"></video> -->
                      

                         <!-- <a-asset-item id="fireplace" src="/objects/fireplace/scene.gltf"></a-asset-item> -->
                         <!-- <a-asset-item id="fire" src="/objects/fire/scene.gltf"></a-asset-item> -->
                                                    
                                           
                      
                            <!-- <video id="fishvideo" autoplay loop="true" src="/images/fish.mp4"></video> -->
                         <!-- <a-asset-item id="pj" src="/js/particles-fireworks.json"></a-asset-item>
                        <img src="/js/sprite.png" id="particleTex"> -->
                        
                    </a-assets>

          <a-entity v-if="!moody" rotation="0 0 0" position="0 -550 0" cubemap="folder: /images/cubemap/; ext:webp"
                   ></a-entity>

        <a-entity v-if="moody" rotation="0 0 0" position="0 -550 0" cubemap="folder: /images/cubemapd/; ext:webp"
                   ></a-entity>
                

     <!-- good working lights 02may2022 -->
            <a-entity id="ambientlight" v-if="instant" light="type: ambient; intensity: 0.9; "></a-entity>
             <a-entity id="ambientlight" v-if="moody" light="type: ambient; intensity: 0.3; "></a-entity>
                 <a-entity id="ambientlight" v-if="neon" light="type: ambient; intensity: 0.7; "></a-entity>
            <a-entity id="ambientlight" v-if="ornate" light="type: ambient; intensity: 0.5; "></a-entity>
            <a-entity id="ambientlight" v-if="wedding" light="type: ambient; intensity: 0.7; "></a-entity>
              <a-entity id="ambientlight" v-if="birthday" light="type: ambient; intensity: 1; "></a-entity>
            <!-- <a-entity light="type: point; distance: 75; castShadow: true; color: white" position="0 24.33785 -75"></a-entity>
            <a-entity light="type: point; intensity: 0.75; distance: 50" position="0 5 -6.82169"></a-entity> -->

                 <a-entity v-if="wedding || birthday" id="individualLightPerPic" 
                                                                   light="type: point; castShadow: true; intensity:8; color:#e25822; distance:50; decay:5 " 
                                                                   position="-25 12.5 -36.5"
                                                                  >
                                                                   
              </a-entity>

               <a-entity v-if="wedding || birthday" id="individualLightPerPic" 
                                                                   light="type: point; castShadow: true; intensity:8; color:#e25822; distance:50; decay:5 " 
                                                                   position="-25 12.5 -85"
                                                                  >
                                                                   
              </a-entity>

                  <a-entity v-if="wedding || birthday" id="individualLightPerPic" 
                                                                   light="type: point; castShadow: true; intensity:8; color:#e25822; distance:50; decay:5 " 
                                                                   position="25 12.5 -36.5"
                                                                  >
                                                                   
              </a-entity>

               <a-entity v-if="wedding || birthday" id="individualLightPerPic" 
                                                                   light="type: point; castShadow: true; intensity:8; color:#e25822; distance:50; decay:5 " 
                                                                   position="25 12.5 -85"
                                                                  >
                                                                   
              </a-entity>
           

             
              <!-- <a-entity v-if="!moody" id="shadowlight"  light="type: point; distance: 0;  castShadow: false; color: #efebd8; intensity: 0.5;" position="0 18 0"></a-entity> -->

              <!-- <a-entity v-if="moody" id="shadowlight"  light="type: point; distance: 0;  castShadow: true; color: #efebd8; intensity: 0.1;" position="0 18 0"></a-entity> -->

              

               <!-- nice 80s effect -->
              <!-- https://www.color-meanings.com/neon-colors/ -->
               <!-- <a-entity v-if="neon" light="type: spot; target:#camera;
               color:#C40070;
               angle:60;
               penumbra:0.1;
               " position="0 8 5"
               animation="property: light.color; from:#C40070; to:#39FF14; dur: 10000; loop:true; easings: easeInOutSine; dir: alternate; autoplay: true; ">
           </a-entity> -->

             <a-entity v-if="moody" id="neonlight1" light="type: point; castShadow: true; intensity:0.25;
                    color:#da0f41;" position="0 20 -75">
                </a-entity>

                <a-entity v-if="instant || ornate || birthday || wedding" id="neonlight1" light="type: point; castShadow: true; intensity:0.4;
                    color:white;" position="0 20 -75">
                </a-entity>

                  <a-entity v-if="neon" id="neonlight1" light="type: point; castShadow: true;
                    color:#C40070;" position="0 20 -75">
                </a-entity>
                <!-- animation="property: position; to: 0 0 -75; dur: 5000; easing: linear; loop: true; dir: alternate" -->

                 
                  <a-entity v-if="neon" id="neonlight2" light="type: spot; target:#camera; 
               color:#39FF14;
               angle:60;
               penumbra:1;
               " position="0 3 5" ></a-entity>
                 <!-- animation="property: position; from:0 13 0; to:0 13 -100; dur: 10000; loop:true; easings: easeInOutSine; dir: alternate; autoplay: true; " -->

   

     

                  <a-entity id="trackcameralight"  v-if="!neon" light="type: spot; target:#camera; 
               color:#F8ECD1;
               angle:60;
               penumbra:1; castShadow:false; intensity: 0.75; distance: 50; decay: 1
               " position="0 3 5" ></a-entity>
          

                      
                    <!-- FLOOR -->
                      <a-plane v-if="!summer" id="ground"  shadow="receive: true; cast: false" position="0 -0.5 0" rotation="-90 90 0" width='1000' height='1000' color="#fff"
                    normal-map="#marblenormal"
                    normal-texture-repeat="75 75"
                    normal-scale="3 3"
                    roughness="0.6"
                    metalness="0.2"                 
                    src="#marble" repeat="75 75" opacity="1" > </a-plane>

                    
                      <a-plane v-if="summer" id="ground"  shadow="receive: true; cast: false" position="0 -0.5 0" rotation="-90 90 0" width='1000' height='1000' color="#fff"
                    normal-map="#marblenormal"
                    normal-texture-repeat="100 100"
                    normal-scale="1 1"
                    roughness="1"
                    metalness="0.1"                 
                    src="#marble" repeat="100 100" opacity="1" > </a-plane>
                    <!-- END OF FLOOR -->
          
                    <!-- SOUND -->
                    <a-box id="soundBox" v-if="!mobileNotOwner || !mobileOwner"
                    sound="src:#level1; loop: true; distanceModel:linear" soundbox
                    src="/images/musicoff.png" 
                    roughness="0.6" metalness="0.2" 
                    width="2" height="2" depth="2" position="0 10 -55"
                    scale="1 1 1"></a-box> 
                   <!-- END OF SOUND -->


                   <!-- CENTRAL GLASS ROOF -->
                      <!-- <a-plane  id="glassceilingcenter"  shadow="receive: true; cast: false" position="0 25 0" rotation="90 90 0" width='25' height='25' color="#fff"
                    normal-map="#glassroofn"
                    normal-texture-repeat="5 5"
                    normal-scale="3 3"
                    roughness="0.6"
                    metalness="0.5"                 
                    src="#glassroofn" repeat="5 5" opacity="0.2" > </a-plane> -->


                    <!-- <a-video src="/videos/intro.mp4" autoplay width="9" height="16" rotation="0 -30 0" position='-2 1.5 -4'></a-video> -->



                    <!-- Instructions info -->
                    <a-entity v-if="ownership">
                       <a-box v-if="desktopOwner" id="instructions" scale='3 3 0.2' position='2 1.5 -4' rotation="0 -30 0" 
                                roughness="0.5" metalness="0.1"               
                                src="/images/instructions-D-O.png">
                        </a-box>
                         <a-box v-if="mobileOwner" id="instructions" scale='3 3 0.2' position='2 1.5 -4' rotation="0 -30 0" 
                                roughness="0.5" metalness="0.1"               
                                src="/images/instructions-M-O.png">
                            </a-box>
                          <!-- <a-box v-show="headsetInVROwner" id="headsetInVROwner" scale='3 3 0.2' position='2 1.5 -4' rotation="0 -30 0" 
                                roughness="0.5" metalness="0.1"               
                                src="/images/instructions-HIVR-O.png">
                          </a-box> -->
                            <a-box v-if="headsetOwner" id="headsetOwner" scale='3 3 0.2' position='2 1.5 -4' rotation="0 -30 0" 
                                roughness="0.5" metalness="0.1"               
                                src="/images/instructions-HNVR-O.png">
                            </a-box> 
                      </a-entity>
                      <a-entity v-if="!ownership">
                            <a-box v-if="desktopNotOwner" id="instructions" scale='3 3 0.2' position='2 1.5 -4' rotation="0 -30 0" 
                                roughness="0.5" metalness="0.1"               
                                src="/images/instructions-D-NO.png">
                            </a-box>
                           
                            <a-box v-if="mobileNotOwner" id="instructions" scale='3 3 0.2' position='2 1.5 -4' rotation="0 -30 0" 
                                roughness="0.5" metalness="0.1"               
                                src="/images/instructions-M-NO.png">
                            </a-box>
                           
                              <a-box v-if="headsetNotOwner" id="headsetNotOwner" scale='3 3 0.2' position='2 1.5 -4' rotation="0 -30 0" 
                                roughness="0.5" metalness="0.1"               
                                src="/images/instructions-HNVR-NO.png">
                            </a-box>
                          
                            <!-- <a-box v-show="headsetInVRNotOwner" id="headsetInVRNotOwner" scale='3 3 0.2' position='2 1.5 -4' rotation="0 -30 0" 
                                roughness="0.5" metalness="0.1"               
                                src="/images/instructions-HIVR-NO.png">
                            </a-box> -->
                      </a-entity>
                        
                            
                            
                                  
                                  <!-- <a-text id="mobile1" class="mobiletext"  visible="false" position="-0.45 0.3 1" value="Touch an image to get the perfect view. " scale="0.18 0.18 0.18" side="double" wrap-count="25" ></a-text>

                                  <a-text id="desktop1" class="desktoptext"  visible="false" position="-0.45 0.3 1"  value="Click an image to get the perfect view. " scale="0.18 0.18 0.18" side="double" wrap-count="25" ></a-text>

                                  <a-text id="headset1" class="headsettext"  visible="false" position="-0.45 0.3 1" value="'Click' an image to get the perfect view. " scale="0.18 0.18 0.18" side="double" wrap-count="25" ></a-text>


                                  <a-text id="mobile2" class="mobiletext" visible="false" position="-0.45 -0.05 1" value="Use the virtual thumb stick (bottom-left) to move around. " scale="0.18 0.18 0.18" side="double" wrap-count="25" ></a-text>

                                  <a-text id="desktop2" class="desktoptext" visible="false" position="-0.45 -0.05 1" value="Use the virtual thumb stick (bottom-left) or keyboard\n arrow keys, to move around. " scale="0.18 0.18 0.18" side="double" wrap-count="25" ></a-text>

                                  <a-text id="headset2" class="headsettext" visible="false" position="-0.45 -0.05 1" value="Use the virtual thumb stick (bottom-left) or keyboard\n arrow keys, to move around. " scale="0.18 0.18 0.18" side="double" wrap-count="25" ></a-text>



                                  <a-text id="addImageText" v-if="ownership" position="-0.45 -0.35 1" value="Add an image using the add image button (bottom right). " scale="0.18 0.18 0.18" side="double" wrap-count="25" ></a-text>

                                  <a-text v-if="!ownership" position="-0.45 -0.35 1" value="Create your own gallery and share your art with the world! " scale="0.18 0.18 0.18" side="double" wrap-count="25" ></a-text> -->
                              

                                  <!-- <a-box  id="logo" depth="0.4" height="0.4" width="0.4" position="2.68 2.85 -7.27" sound="src: /sounds/metalcrash.mp3; on: click" src="/images/squarelogo.png" material="shader:standard"
                                    animation="property: rotation; to:0 360 0; dir:alternate; loop: true; dur: 10000" class="navigationpoint"></a-box>  -->

           

                    <!-- <a-box position="0 2.5 -50" width="5" height="5" depth="5"
                       normal-map="/images/tilesnormal.jpg"
                      normal-texture-repeat="1 1"
                      normal-scale="3 3"
                      roughness="0.5"                 
                      src="/images/tiles.jpg" repeat="1 1" opacity="1"
                    ></a-box> -->


                  <!-- HORIZON!!!!!!!!!!! -->
                <!-- background buildings on the horizon -->
          
<!-- 
                   <a-entity id='robotx' static-body  gltf-model="/objects/robot/scene.gltf"  rotation="0 0 0" animation-mixer="clip:Idle" position="0 6.5 -10" scale="0.05 0.05 0.05" animation="property: position; to: 0 6.5 -8;  dur: 3000" shadow >
                    <a-entity raycaster="objects: .collidable; showLine: true; far: 300" position="-55 -70 0" rotation="-7.3 180 0"
                    animation="property: rotation; to: -7.3 200 0; loop:true;  dur: 5000"></a-entity>
                </a-entity> -->



             
                    <a-entity  class="building1" position="0 0 -20" rotation="0 0 0">

                        <a-entity class="collection1" position="0 0 -10" rotation="0 0 0">
                                  
                                    <a-box id="info" mixin="banner"  position="0.08265 15 -10" rotation="" width="20" height="5" depth="0.2">
                                            <a-text  position="-8.5 -0.3 1" :value="gallery.title" 
                                            font="/fonts/nunito/Nunito-Regular.json" shader="msdf" scale="6 6 6" ></a-text>
                                            <a-text  position="-8 -2 1.5" :value="gallery.userName" 
                                            font="/fonts/nunito/Nunito-Regular.json" shader="msdf" scale="4 4 4" ></a-text>
                                    </a-box>

                                  <a-box id="tapimageinfo" position="0.08265 10 -10" color="#da0f41" width="10" height="2.5" rotation="0 0 0">
                                         <a-text id="tapimagetext"  position="-0 -0.5 1" :value="taporclick + ' to focus on an image.'" text="width: 3.5; align: center"
                                            font="/fonts/nunito/Nunito-Regular.json" shader="msdf" scale="5 5 5" ></a-text>
                                            <!-- <a-text  position="-8.5 -0.3 1" value="Tap an image to zoom in" 
                                            font="/fonts/nunito/Nunito-Regular.json" shader="msdf" scale="3 3 3" text="align: center" ></a-text> -->
                                  </a-box>

                                    <!-- <a-box id="info" mixin="banner"  position="0.08265 13.35 -10" rotation="" width="10" height="10" depth="10">
                                            <a-text  position="-25 5 1" :value="gallery.title" 
                                            font="/fonts/nunito/Nunito-Regular.json" shader="msdf" scale="6 6 6" ></a-text>
                                            <a-text  position="-8 -2 1.5" :value="gallery.userName" 
                                            font="/fonts/nunito/Nunito-Regular.json" shader="msdf" scale="4 4 4" ></a-text>
                                    </a-box>  -->

                                       <!-- <a-box id="info" mixin="banner"  position="0.08265 10 25" rotation="" width="20" height="5" depth="0.2">
                                            <a-text  position="-8.5 -0.3 1" :value="gallery.title" 
                                            font="/fonts/nunito/Nunito-Regular.json" shader="msdf" scale="6 6 6" ></a-text>
                                            <a-text  position="-8 -2 1.5" :value="gallery.userName" 
                                            font="/fonts/nunito/Nunito-Regular.json" shader="msdf" scale="4 4 4" ></a-text>
                                    </a-box> -->

                                     <!-- <a-box id="info" mixin="banner"  position="0.08265 10 -10" rotation="" width="20" height="5" depth="0.2">
                                            <a-text  position="-8.5 -0.3 1" :value="gallery.title" 
                                            font="/fonts/nunito/Nunito-Regular.json" shader="msdf" scale="6 6 6" ></a-text>
                                            <a-text  position="-8 -2 1.5" :value="gallery.userName" 
                                            font="/fonts/nunito/Nunito-Regular.json" shader="msdf" scale="4 4 4" ></a-text>
                                    </a-box> -->


                              
                                  <!-- <a-entity v-for="(pic, index) in people.faves" :key="pic.id">
                                  </a-entity> -->


                                    <!-- load our imagee here -->
                                            <a-entity v-for="(pic, index) in gallery.pics" :key="pic.id">
                                                    <a-entity position="0 0.5 -3" >
                                                                <!-- pic title -->
                                                                <!-- TOOK THIS OUT AS HAVING ALL FACE THE VIEWER, POSSIBLY PUT BACK IN -->
                                                            <!-- <a-entity :position="imagePositionsArray[index]" :rotation="imageRotationArray[index]" id="imageEntity"> -->
                                                              <a-entity :position="imagePositionsArray[index]" rotation="0 0 0" id="imageEntity">
                                                                <!-- <a-text :value="pic.title" position="2 0 0.2">
                                                                   
                                                               </a-text>  -->
                                                               <!-- Information box and description for each image -->
                                                               <a-box v-if="!vrheadset" id="notsure" depth="0.01" rotation="0 -25 0"
                                                                      height="2" width="1.5"
                                                                      :position="(pic.originalWidth/1000/2)+1.25 + ' -0.26 0.6'" 
                                                                      roughness="1" metalness="0.3"
                                                                      normal-map="#concreten" 
                                                                      normal-texture-repeat="1 1" 
                                                                      normal-scale="1 1"
                                                                      color="white"
                                                                      >

                                                                      <!-- color="#da0f41" #8e8e93-->
                                                                          <!-- FAVE BUTTON -->
                                                                          <a-image v-if="user !== null && people && favedpic(pic.resizedUrl)" id="addFaveButton"  rotation="0 0 0"
                                                                            position="-0.55 0.8 0.1" 
                                                                            src="#faveselected" transparent="true"
                                                                            class="collidable"
                                                                            roughness="1" metalness="0.3" shader="standard"           
                                                                            scale="0.25 0.25 0.05"
                                                                          > </a-image>

                                                                          <a-image v-if="user !== null && people && !favedpic(pic.resizedUrl)" id="addFaveButton"  rotation="0 0 0"
                                                                            position="-0.55 0.8 0.1" 
                                                                            :addfave='"url:" + pic.resizedUrl + ";" + "thumbnailUrl:" + pic.thumbnailUrl + ";"+ "width:" + pic.originalWidth + ";" + "height:" + pic.originalHeight + ";" + "title:" + pic.title'
                                                                            src="#fave" transparent="true"
                                                                            class="collidable"
                                                                            roughness="1" metalness="0.3" shader="standard"           
                                                                            scale="0.25 0.25 0.05"
                                                                            sound="src: #metalcrash; on: click" 
                                                                           
                                                                            animation="property: scale; from:0.25 0.25 0.25; to:0.3 0.3 0.3; dur: 1000;  dir:alternate; loop: true;autoplay: true; "> 
                                                                          </a-image>
                                                                           <!-- animation="property: rotation; from:0 -10 0; to:0 10 0; dur: 1000;  dir:alternate; loop: true;autoplay: true; "
                                                               -->
                                                              
                                                                     
                                                                         <a-entity v-if="pic.title"
                                                                          scale="0.4 0.4 0.4"
                                                                          position="0 0.1 0.1" 
                                                                          :text="'font:/fonts/nunito/Nunito-Regular.json; shader: msdf; color:black;  value: ' + pic.title + '\n\n' + pic.description + '\n ; align:left; xOffset:0; width: 2.8; wrapCount:15;'"
                                                                          ></a-entity>

                                                                          <!-- <a-text color="black"  value="goToSchatzkin" 
                                                                          mylink="href: https://schatzkin.com;"></a-text> -->
                                                                          <!-- LINK BUTTON -->
                                                                           <a-box v-if="pic.picurl"
                                                                              scale="0.25 0.25 0.05"
                                                                              position="-0.55 -0.68 0.1" 
                                                                              src="#linkbox"
                                                                              roughness="0.6" metalness="0.5" shader="standard"   
                                                                              :mylink="'href:' + pic.picurl + '; type: self'" 
                                                                              class="collidable"
                                                                              >
                                                                                <a-entity 
                                                                                scale="0.85 0.85 0.85"
                                                                                position="2.5 -0.2 0.4" 
                                                                                :text="'font:/fonts/nunito/Nunito-Regular.json; shader: msdf; color:#10559a;  value: ' + pic.picurl + ' ; align:left; xOffset:0; width: 4; wrapCount:20;'"
                                                                                :mylink="'href:' + pic.picurl + '; type: self'"
                                                                                class="collidable"
                                                                                ></a-entity>
                                                                          </a-box>
                                                                          <!-- end LINK BUTTON -->
                                                                                                                                                    <!-- :mylink="'href:' + pic.picurl" -->

                                                                                                                                                    <!-- text="font:/fonts/nunito/Nunito-Regular.json; shader: msdf; color:white;  value: Link; align:left; xOffset:0; width: 2.8; wrapCount:15;" -->

                                                               </a-box>

                                                                  <a-box v-if="vrheadset"  id="notsure" depth="0.01" rotation="0 -25 0"
                                                                      height="2" width="1.5"
                                                                      :position="(pic.originalWidth/500/2)+1.25 + ' -0.26 0.6'" 
                                                                      roughness="1" metalness="0.3"
                                                                      normal-map="#concreten" 
                                                                      normal-texture-repeat="1 1" 
                                                                      normal-scale="1 1"
                                                                      color="white">

                                                                         <!-- FAVE BUTTON -->
                                                                          <!-- <a-image v-if="user !== null" id="addFaveButton" depth="0.1" rotation="0 0 0"
                                                                          position="-0.6 0.8 0.1'"
                                                                          :addfave='"url:" + pic.resizedUrl + ";" + "width:" + pic.originalWidth + ";" + "height:" + pic.originalHeight + ";" + "title:" + pic.title'
                                                                          src="#fave" transparent="true"
                                                                          class="collidable"
                                                                          roughness="0.6" metalness="0.5" shader="standard"             
                                                                          scale="0.25 0.25 0.25"
                                                                          sound="src: #metalcrash; on: click" 
                                                                          animation__01="property: scale; from:0.25 0.25 0.25; to:0.3 0.3 0.3; dur: 1000;  dir:alternate; loop: true;autoplay: true; "> </a-image> -->

                                                                          <a-image v-if="user !== null && people && favedpic(pic.resizedUrl)" id="addFaveButton"  rotation="0 0 0"
                                                                            position="-0.55 0.8 0.1" 
                                                                            src="#faveselected" transparent="true"
                                                                            class="collidable"
                                                                            roughness="1" metalness="0.3" shader="standard"           
                                                                            scale="0.25 0.25 0.05"
                                                                          > </a-image>

                                                                          <a-image v-if="user !== null && people && !favedpic(pic.resizedUrl)" id="addFaveButton"  rotation="0 0 0"
                                                                            position="-0.55 0.8 0.1" 
                                                                            :addfave='"url:" + pic.resizedUrl + ";" + "thumbnailUrl:" + pic.thumbnailUrl + ";"+ "width:" + pic.originalWidth + ";" + "height:" + pic.originalHeight + ";" + "title:" + pic.title'
                                                                            src="#fave" transparent="true"
                                                                            class="collidable"
                                                                            roughness="1" metalness="0.3" shader="standard"           
                                                                            scale="0.25 0.25 0.05"
                                                                            sound="src: #metalcrash; on: click" 
                                                                           
                                                                            animation="property: scale; from:0.25 0.25 0.25; to:0.3 0.3 0.3; dur: 1000;  dir:alternate; loop: true;autoplay: true; "> 
                                                                          </a-image>
                                                              
                                                              
                                                                     
                                                                         <a-entity 
                                                                          scale="0.4 0.4 0.4"
                                                                          position="0 -0.1 0.1" 
                                                                          :text="'font:/fonts/nunito/Nunito-Regular.json; shader: msdf; color:black;  value: ' + pic.title + '\n\n' + pic.description + '\n ; align:left; xOffset:0; width: 2.8; wrapCount:15;'"
                                                                          ></a-entity>

                                                                          <!-- LINK BUTTON for VR-->
                                                                          <a-box v-if="pic.picurl"
                                                                              scale="0.25 0.25 0.05"
                                                                              position="-0.55 -0.68 0.1" 
                                                                              src="#linkbox"
                                                                              roughness="0.6" metalness="0.5" shader="standard"   
                                                                              :mylink="'href:' + pic.picurl + '; type: self'" 
                                                                              class="collidable"
                                                                              >
                                                                                <a-entity 
                                                                                scale="0.85 0.85 0.85"
                                                                                position="2.5 -0.2 0.4" 
                                                                                :text="'font:/fonts/nunito/Nunito-Regular.json; shader: msdf; color:#10559a;  value: ' + pic.picurl + ' ; align:left; xOffset:0; width: 4; wrapCount:20;'"
                                                                                :mylink="'href:' + pic.picurl + '; type: self'"
                                                                                class="collidable"
                                                                                ></a-entity>
                                                                          </a-box>
                                                                          <!-- end LINK BUTTON -->
                                                                                       
                                                               </a-box>

                                                                <!-- need to check if logged in to show fave button -->
                                                                <!-- ADD FAVE HERECURRENTLY WORKING ON -->
                                                            

                                                                <!-- <a-box v-if="vrheadset && user !== null" id="addFaveButton" depth="0.1" rotation="0 -25 0"
                                                                      :position="(pic.originalWidth/500/2)+0.8 + ' 0.9 0.2'"
                                                                       :addfave='"txt:" + pic.resizedUrl' src="#fave" class="collidable"
                                                                      
                                                                      normal-scale="3 3" 
                                                                      roughness="0.6" metalness="0.1"            
                                                                      scale="0.5 0.5 0.5"
                                                                      sound="src: #metalcrash; on: click" > </a-box> -->
                                                    
                                                             
                                                                <!-- PICTURE FRAME HERE -->
                                                                <!-- DIFFERENT SIZE FRAME BASED ON HOW BIG THE IMAGE IS, ITS DOUBLED FOR VR -->
                                                                <a-box v-if="!vrheadset && instant || !vrheadset && moody"  :width="(pic.originalWidth/1000)*1.1" :height="(pic.originalHeight/1000)*1.1" depth="0.15" position="0 0 -0.25" 
                                                                    shadow="receive: false; cast: true" 
                                                                     material="roughness: 0.6; metalness:0.2; transparent:true; "  opacity="0.2"
                                                                   >
                                                                </a-box>

                                                                  <a-box v-if="!vrheadset && neon"  :width="(pic.originalWidth/1000)*1.2" :height="(pic.originalHeight/1000)*1.2" depth="0.15" position="0 0 -0.25" 
                                                                    shadow="receive: false; cast: true" 
                                                                       opacity="1" src="#80sfilter"
                                                                       
                                                                   >
                                                                </a-box>
                                                                        <!-- neon filter infron of image -->
                                                                        <!-- <a-box v-if="!vrheadset && neon"  :width="(pic.originalWidth/1000)*1" :height="(pic.originalHeight/1000)*1" depth="0.15" position="0 0 0.05" 
                                                                            shadow="receive: false; cast: true" 
                                                                              opacity="0.2"   color="#F8ECD1"                        
                                                                              material="roughness: 0.4; metalness:0.8;  "     
                                                                          >
                                                                        </a-box> -->
                                                                  <!-- BIRTHDAY LANDSCAPE -->
                                                                 <a-box v-if="!vrheadset && birthday && pic.originalWidth >= pic.originalHeight"  :width="(pic.originalWidth/1000)*1.7" :height="(pic.originalHeight/1000)*1.4" depth="0.05" position="0 0.35 0.05" 
                                                                    shadow="receive: false; cast: true" 
                                                                      normal-map="#picframen"
                                                                      normal-texture-repeat="1 1"
                                                                      normal-scale="1 1"                             
                                                                      material="roughness: 1; metalness:0.1; transparent:true"                  
                                                                      src="#picframe" repeat="1 1" donext class="building1" 
                                                                    >
                                                                </a-box>
                                                                <!-- BIRTHDAY PORTRAIT -->
                                                                  <a-box v-if="!vrheadset && birthday && pic.originalWidth < pic.originalHeight"  
                                                                  :width="(pic.originalWidth/1000)*1.7" 
                                                                  :height="(pic.originalHeight/1000)*1.4" depth="0.05" 
                                                                  position="-0.6 0.5 0.1" 
                                                                    shadow="receive: false; cast: true" 
                                                                      normal-map="#picframehn"
                                                                      normal-texture-repeat="1 1"
                                                                      normal-scale="3 3"                             
                                                                      material="roughness: 1; metalness:0.1; transparent:true"                  
                                                                      src="#picframeh" repeat="1 1" donext class="building1" 
                                                                    >
                                                                </a-box>
                                                                <!-- picframehn -->

                                                                        <!-- if landscape -->
                                                                      <a-box v-if="!vrheadset && wedding && pic.originalWidth > pic.originalHeight"  :width="(pic.originalWidth/1000)*1.26" :height="(pic.originalHeight/1000)*1.27" depth="0.05" 
                                                                       position="0 0 0.1" 
                                                                    shadow="receive: false; cast: true" 
                                                                      normal-map="#picframen"
                                                                      normal-texture-repeat="1 1"
                                                                      normal-scale="3 3"                             
                                                                      material="roughness: 0.9; metalness:0.3; transparent:true; opacity:1"                  
                                                                      src="#picframe" repeat="1 1" donext class="building1" 
                                                                    >
                                                                </a-box>
                                                                  <!-- if portrait -->
                                                                  <a-box v-if="!vrheadset && wedding && pic.originalWidth < pic.originalHeight"  
                                                                  :width="(pic.originalWidth/1000)*1.35" 
                                                                  :height="(pic.originalHeight/1000)*1.2" depth="0.05" 
                                                                       position="0.1 0.1 0.1" 
                                                                    shadow="receive: false; cast: true" 
                                                                      normal-map="#picframe2n"
                                                                      normal-texture-repeat="1 1"
                                                                      normal-scale="3 3"                             
                                                                      material="roughness: 0.9; metalness:0.3; transparent:true; opacity:1"                  
                                                                      src="#picframe2" repeat="1 1" donext class="building1" 
                                                                    >
                                                                </a-box>

                                                                <!-- ornate if landscape -->
                                                              <a-box v-if="!vrheadset && ornate && pic.originalWidth >= pic.originalHeight"  
                                                                :width="(pic.originalWidth/1000)*1.05" 
                                                                :height="(pic.originalHeight/1000)*1" depth="0.15" position="0 0 0.05" scale="1.1 1.2 1"
                                                                    shadow="receive: false; cast: true" 
                                                                      normal-map="#picframen"
                                                                      normal-texture-repeat="1 1"
                                                                      normal-scale="1 1"                             
                                                                      material="roughness: 1; metalness:0.2; transparent:true"                  
                                                                      src="#picframe" repeat="1 1" donext class="building1" 
                                                                    >
                                                                </a-box>

                                                                      <!-- ornate if portrait -->
                                                              <a-box v-if="!vrheadset && ornate && pic.originalWidth <  pic.originalHeight"  
                                                              :width="(pic.originalWidth/1000)*1.1" 
                                                              :height="(pic.originalHeight/1000)*1.08" depth="0.15" position="0 0 0.05" scale="1 1 1"
                                                                    shadow="receive: false; cast: true" 
                                                                      normal-map="#picframepn"
                                                                      normal-texture-repeat="1 1"
                                                                      normal-scale="1 1"                             
                                                                      material="roughness: 1; metalness:0.2; transparent:true"                  
                                                                      src="#picframep" repeat="1 1" donext class="building1" 
                                                                    >
                                                                </a-box>

                                                                

                                                                <a-box v-if="vrheadset && instant || vrheadset && moody" :width="(pic.originalWidth/500)*1.1" :height="(pic.originalHeight/500)*1.1" depth="0.15" 
                                                                :position="'0 '+ pic.originalHeight/2000 +' -0.25'" 
                                                                    shadow="receive: false; cast: true" 
                                                                    material="roughness: 0.6; metalness:0.2; transparent:true; "  opacity="0.2"
                                                                   >
                                                                </a-box>  

                                                                <a-box v-if="vrheadset && neon"  :width="(pic.originalWidth/500)*1.1" :height="(pic.originalHeight/500)*1.1" depth="0.15" 
                                                                 :position="'0 '+ pic.originalHeight/2000 +' -0.25'"
                                                                    shadow="receive: false; cast: true" 
                                                                       opacity="1" src="#80sfilter"
                                                                   >
                                                                </a-box>

                                                                             <!-- neon filter infron of image -->
                                                                        <!-- <a-box v-if="vrheadset && neon"  :width="(pic.originalWidth/500)*1" :height="(pic.originalHeight/500)*1" depth="0.15" 
                                                                        :position="'0 '+ pic.originalHeight/2000 +' 0.1'" 
                                                                            shadow="receive: false; cast: true" 
                                                                              opacity="0.15"  src="#80sfilter"
                                                                              normal-map="#neonfiltern"
                                                                              normal-texture-repeat="1 1"
                                                                              normal-scale="1 1"     color="white"                        
                                                                              material="roughness: 0.2; metalness:0.9;  "     
                                                                          >
                                                                        </a-box> -->

                                                               <!-- BIRTHDAY LANDSCAPE -->
                                                                 <a-box v-if="vrheadset && birthday && pic.originalWidth >= pic.originalHeight"  :width="(pic.originalWidth/500)*1.25" :height="(pic.originalHeight/500)*1.1" depth="0.05" 
                                                                   :position="'0 '+ pic.originalHeight/1250 +' -0.1'" 
                                                                    shadow="receive: false; cast: true" 
                                                                      normal-map="#picframen"
                                                                      normal-texture-repeat="1 1"
                                                                      normal-scale="1 1"                             
                                                                      material="roughness: 1; metalness:0.1; transparent:true"                  
                                                                      src="#picframe" repeat="1 1" donext class="building1" 
                                                                    >
                                                                </a-box>
                                                                <!-- BIRTHDAY PORTRAIT -->
                                                                  <a-box v-if="vrheadset && birthday && pic.originalWidth < pic.originalHeight"  
                                                                  :width="(pic.originalWidth/500)*1.6" 
                                                                  :height="(pic.originalHeight/500)*1.3" depth="0.05" 
                                                                  :position="'-1 '+ pic.originalHeight/1350 +' 0.1'" 
                                                                    shadow="receive: false; cast: true" 
                                                                      normal-map="#picframehn"
                                                                      normal-texture-repeat="1 1"
                                                                      normal-scale="3 3"                             
                                                                      material="roughness: 1; metalness:0.1; transparent:true"                  
                                                                      src="#picframeh" repeat="1 1" donext class="building1" 
                                                                    >
                                                                </a-box>
                                                                <!-- if wedding landscape -->
                                                                      <a-box v-if="vrheadset && wedding && pic.originalWidth > pic.originalHeight"  :width="(pic.originalWidth/500)*1.27" :height="(pic.originalHeight/500)*1.27" depth="0.05" 
                                                                       :position="'0 '+ pic.originalHeight/2000 +' 0.1'" 
                                                                    shadow="receive: false; cast: true" 
                                                                      normal-map="#picframen"
                                                                      normal-texture-repeat="1 1"
                                                                      normal-scale="3 3"                             
                                                                      material="roughness: 0.9; metalness:0.3; transparent:true; opacity:1"                  
                                                                      src="#picframe" repeat="1 1" donext class="building1" 
                                                                    >
                                                                </a-box>
                                                                  <!-- if portrait -->
                                                                  <a-box v-if="vrheadset && wedding && pic.originalWidth < pic.originalHeight"  
                                                                  :width="(pic.originalWidth/500)*1.35" 
                                                                  :height="(pic.originalHeight/500)*1.2" depth="0.05" 
                                                                       :position="'0.1 '+ pic.originalHeight/1850 +' 0.1'" 
                                                                    shadow="receive: false; cast: true" 
                                                                      normal-map="#picframe2n"
                                                                      normal-texture-repeat="1 1"
                                                                      normal-scale="3 3"                             
                                                                      material="roughness: 0.9; metalness:0.3; transparent:true; opacity:1"                  
                                                                      src="#picframe2" repeat="1 1" donext class="building1" 
                                                                    >
                                                                </a-box>

                                                                           <!-- ornate if landscape -->
                                                              <a-box v-if="vrheadset && ornate && pic.originalWidth >= pic.originalHeight"  
                                                                :width="(pic.originalWidth/500)*1.05" 
                                                                :height="(pic.originalHeight/500)*1" depth="0.15" 
                                                                :position="'0 '+ pic.originalHeight/2000 +' 0.05'" 
                                                                 scale="1.1 1.2 1"
                                                                    shadow="receive: false; cast: true" 
                                                                      normal-map="#picframen"
                                                                      normal-texture-repeat="1 1"
                                                                      normal-scale="1 1"                             
                                                                      material="roughness: 1; metalness:0.2; transparent:true"                  
                                                                      src="#picframe" repeat="1 1" donext class="building1" 
                                                                    >
                                                                </a-box>

                                                                      <!-- ornate if portrait -->
                                                              <a-box v-if="vrheadset && ornate && pic.originalWidth <=  pic.originalHeight"  
                                                              :width="(pic.originalWidth/500)*1.1" 
                                                              :height="(pic.originalHeight/500)*1.08" depth="0.15" 
                                                              :position="'0 '+ pic.originalHeight/2000 +' 0.05'" 
                                                              scale="1 1 1"
                                                                    shadow="receive: false; cast: true" 
                                                                      normal-map="#picframepn"
                                                                      normal-texture-repeat="1 1"
                                                                      normal-scale="1 1"                             
                                                                      material="roughness: 1; metalness:0.2; transparent:true"                  
                                                                      src="#picframep" repeat="1 1" donext class="building1" 
                                                                    >
                                                                </a-box>

                                                                <!-- END OF PICTURE FRAME   -->
                                                                
                                                                <a-box v-if="vrheadset" :id="pic.resizedUrl" static-body class="building1 collidable" 
                                                                :position="'0 '+ pic.originalHeight/2000 +' 0'" 
                                                                :src="'#image' + index" 
                                                                shadow="receive: false; cast: false" shader="flat" crossorigin="anonymous" :width="pic.originalWidth/500" :height="pic.originalHeight/500" style="pointer-events:none"  depth="0.1" donext ></a-box>

                                                                 <a-box v-if="!vrheadset" :id="pic.resizedUrl" static-body class="building1 collidable" position="0 0 0" 
                                                                :src="'#image' + index" 
                                                                shadow="receive: false; cast: false" shader="flat" crossorigin="anonymous" :width="pic.originalWidth/1000" :height="pic.originalHeight/1000" style="pointer-events:none" depth="0.1"  donext ></a-box>
                                                               
                                                                <a-image id="deleteButton" v-if="editMode" scale="1 1 1" position="-1.5 1.75 0.25" material="color:red" @click="handlePicDelete(pic.id)" src="#close" visible="true">
                                                                  <a-text value="Delete" 
                                                                      rotation="0 0 0" scale="1 1 1" align="left" color="#000" position="0.5 0 0">
                                                                  </a-text>
                                                                </a-image>
                                                                

                                                            </a-entity>  
                                                               <!-- :src="'#image' + index"   -->
                                                            
                                                        </a-entity>
                                            </a-entity>

                                </a-entity>
              
                              <!-- ceiling    -->
                           <a-box id="woodceiling" shadow="receive: false; cast: true" position="0 24.5 -40" rotation="-90 0 0" width='50' height='100' depth="0.2" 
                             normal-map="#windownormal"
                            normal-texture-repeat="5 10"
                            normal-scale="3 3"                             
                            material="roughness: 1; metalness:0.5; transparent:true"                  
                            src="#window" repeat="5 10" opacity="1"> </a-box>

                                  <a-box id="woodceiling1" shadow="receive: false; cast: true" position="0 24 10" rotation="0 0 0" width='50' height='1' depth="1" 
                             normal-map="#trimn"
                            normal-texture-repeat="5 1"
                            normal-scale="3 3"                             
                            material="roughness: 1; metalness:0.5; transparent:true;" mixin="banner"                  
                            src="#trim" repeat="5 1" opacity="1"> </a-box>

                             <a-box id="woodceiling2" shadow="receive: false; cast: true" position="0 25 35" rotation="0 90 0" width='55' height='1' depth="1" 
                             normal-map="#trimn"
                            normal-texture-repeat="5 1"
                            normal-scale="3 3"                             
                            material="roughness: 1; metalness:0.5; transparent:true;" mixin="banner"                  
                            src="#trim" repeat="5 1" opacity="1"> </a-box>

                               <a-box id="woodceiling2a" shadow="receive: false; cast: true" position="24.5 24 35" rotation="0 90 0" width='49' height='1' depth="1" 
                             normal-map="#trimn"
                            normal-texture-repeat="5 1"
                            normal-scale="3 3"                             
                            material="roughness: 1; metalness:0.5; transparent:true;" mixin="banner"                  
                            src="#trim" repeat="5 1" opacity="1"> </a-box>

                               <a-box id="woodceiling2b" shadow="receive: false; cast: true" position="-24.5 24 35" rotation="0 90 0" width='49.5' height='1' depth="1" 
                             normal-map="#trimn"
                            normal-texture-repeat="5 1"
                            normal-scale="3 3"                             
                            material="roughness: 1; metalness:0.5; transparent:true;" mixin="banner"                  
                            src="#trim" repeat="5 1" opacity="1"> </a-box>

                             <a-box id="woodceiling3" shadow="receive: false; cast: true" position="0 24 35" rotation="0 0 0" width='48' height='1' depth="1" 
                             normal-map="#trimn"
                            normal-texture-repeat="5 1"
                            normal-scale="3 3"                             
                            material="roughness: 1; metalness:0.5; transparent:true;" mixin="banner"                  
                            src="#trim" repeat="5 1" opacity="1"> </a-box>

                            <a-box id="woodceiling3a" shadow="receive: false; cast: true" position="0 24 59.5" rotation="0 0 0" width='50' height='1' depth="1" 
                             normal-map="#trimn"
                            normal-texture-repeat="5 1"
                            normal-scale="3 3"                             
                            material="roughness: 1; metalness:0.5; transparent:true;" mixin="banner"                  
                            src="#trim" repeat="5 1" opacity="1"> </a-box>


                                 <!-- <a-box id="woodceiling1" shadow="receive: false; cast: true" position="0 24 10" rotation="0 0 0" width='49.5' height='1' depth="1" 
                             normal-map="#trimn"
                            normal-texture-repeat="5 1"
                            normal-scale="3 3"                             
                            material="roughness: 1; metalness:0.5; transparent:true"                  
                            src="#trim" repeat="5 1" opacity="1"> </a-box>
                         -->



                        <!-- leftwall     -->
                        <a-box id="leftwall" v-if="instant || neon || summer || birthday || wedding" position="-25 12.05 -15" rotation="0 90 0" width="150" height="25" depth="0.2" 
                             normal-map="#wallmaterialn"
                            normal-texture-repeat="12 1"
                            normal-scale="3 3"                             
                            material="roughness: 1; metalness:0.5; transparent:true; "                              
                            src="#wallmaterial" repeat="12 1" opacity="1" 
                            ></a-box>

                    

                            <!-- box with normal map, lighting etc. does it look better flat? -->
                              <!-- <a-box id="leftwall" v-if="instant || neon || summer || birthday || wedding" position="-25 12 -15" rotation="0 90 0" width="150" height="25" depth="0.2" 
                             normal-map="#wallmaterialn"
                            normal-texture-repeat="12 1"
                            normal-scale="3 3"                             
                            material="roughness: 0.6; metalness:0.2; transparent:true; "                  
                            src="#wallmaterial" repeat="12 1" opacity="1" 
                            ></a-box> -->

                              <a-box id="leftwall" v-if="moody" position="-25 12.05 -15" rotation="0 90 0" width="150" height="25" depth="0.2" 
                             normal-map="#wallmaterialn"
                            normal-texture-repeat="12 1"
                            normal-scale="3 3"                             
                            material="roughness: 0.8; metalness:1; transparent:true"                  
                            src="#wallmaterial" repeat="12 1" opacity="1" 
                            ></a-box>

                               <a-box id="leftwall" v-if="halloween" position="-25 12.05 -40" rotation="0 90 0" width="100" height="25" depth="0.2" 
                             normal-map="#wallmaterialn"
                            normal-texture-repeat="6 1"
                            normal-scale="3 3"                             
                            material="roughness: 0.8; metalness:1; transparent:true"                  
                            src="#wallmaterial" repeat="6 1" opacity="1" 
                            ></a-box>

                             <a-box id="leftwall" v-if="ornate" position="-25 12.05 -15" rotation="0 90 0" width="150" height="25" depth="0.2" 
                             normal-map="#wallmaterialn"
                            normal-texture-repeat="6 1"
                            normal-scale="3 3"                             
                            material="roughness: 0.8; metalness:0.5; transparent:true"                  
                            src="#wallmaterial" repeat="6 1" opacity="1" 
                            ></a-box>

                     
                            
                         
                          <!-- rightwall     -->
                        
                        <a-box id="rightwall" v-if=" instant || neon || summer || birthday || wedding" position="25 12.05 -40" rotation="0 90 0" width="100" height="25" depth="0.2" 
                             normal-map="#wallmaterialn"
                            normal-texture-repeat="8 1"
                            normal-scale="3 3"                             
                            material="roughness: 1; metalness:0.5; transparent:true"                  
                            src="#wallmaterial" repeat="8 1" opacity="1" 
                            ></a-box>

                     

                              <a-box id="rightwall" v-if="moody" position="25 12.05 -40" rotation="0 90 0" width="100" height="25" depth="0.2" 
                             normal-map="#wallmaterialn"
                            normal-texture-repeat="6 1"
                            normal-scale="3 3"                             
                            material="roughness: 0.8; metalness:1; transparent:true"                  
                            src="#wallmaterial" repeat="6 1" opacity="1" 
                            ></a-box>

                             <a-box id="rightwall" v-if="ornate" position="25 12.05 -40" rotation="0 90 0" width="100" height="25" depth="0.2" 
                             normal-map="#wallmaterialn"
                            normal-texture-repeat="4 1"
                            normal-scale="1 1"                             
                            material="roughness: 0.8; metalness:0.5; transparent:true"                  
                            src="#wallmaterial" repeat="4 1" opacity="1" 
                            ></a-box>

                           <!-- <a-box  id="border" v-if="!vrheadset"  
                                    position="0 -14.5 -0.275"
                                    width="100" height="1" depth="0.2" 
                                    normal-map="#bordernormal"
                                  normal-texture-repeat="20 1"
                                  normal-scale="2 2"></a-box> -->

                                <!-- <a-box scale='2 1.78 0.2' position='-47.5 -1.5 -0.5'  material=" transparent:true; ">
                                  <a-text  position="0 0 -1" value="Click an image to zoom " scale="1 1 1" side="double" rotation="0 180 0"></a-text>
                                  <a-text  position="0.75 -0.25 -10" value="Delete, rotate images. Delete entire gallery." scale="0.5 0.5 0.5" ></a-text>
                              </a-box> -->
                                <!-- bottom border -->
                              

                                    
                            

                     
                               <!-- backwall     -->
                            <a-box id="backwall" v-if="instant || neon || summer || birthday || wedding"  position="0 12.05 -90" rotation="0 0 0" depth="0.2"  geometry="width: 50; height: 25" 
                             normal-map="#wallmaterialn"
                            normal-texture-repeat="4 1"
                            normal-scale="3 3"                             
                            material=" transparent:true; roughness:1; metalness:0.5;   "               
                            src="#wallmaterial" repeat="4 1" opacity="1"                            
                             ></a-box>

                          

                                 <a-box id="backwall" v-if="moody"   position="0 12.05 -90" rotation="0 0 0" depth="0.2"  geometry="width: 50; height: 25" 
                             normal-map="#wallmaterialn"
                            normal-texture-repeat="4 1"
                            normal-scale="3 3"                             
                            material=" transparent:true; roughness:0.8; metalness:1;  "               
                            src="#wallmaterial" repeat="4 1" opacity="1"                            
                             ></a-box>

                               <a-box id="backwall" v-if="ornate"   position="0 12.05 -90" rotation="0 0 0" depth="0.2"  geometry="width: 50; height: 25" 
                             normal-map="#wallmaterialn"
                            normal-texture-repeat="2 1"
                            normal-scale="3 3"                             
                            material=" transparent:true; roughness:0.8; metalness:1; "               
                            src="#wallmaterial" repeat="2 1" opacity="1"                            
                             ></a-box>

                      
                              
                                
                            

                 

                    </a-entity>

                    <a-entity  class="building2" position="22.5 0 2.5" rotation="0 270 0">

                              <a-entity class="collection1" position="0 0 -10" rotation="0 0 0">
                                  
                                    <a-box id="info" mixin="banner"  position="12.5 13.35 -10" rotation="" width="20" height="5" depth="0.2">
                                            <a-text  position="-8 1 1" value="Your favorites" scale="7 7 7" ></a-text>
                                            <a-text v-if="user"  position="-8 -1 1" :value="'As chosen by you'" scale="3 3 3" ></a-text>
                                            <a-text v-if="!user" position="-8 -1 1" :value="'Login or create account to start adding to your faves!'" scale="3 3 3" ></a-text>
                                    </a-box>
                                    <!-- load our imagee here -->

                                          <a-image v-if="user !== null" id="showFavesButton" depth="0.1" rotation="0 0 0"
                                                                          position="12.5 5 1'"
                                                                          showfavescomponent 
                                                                          src="/images/showfaves.webp"  transparent="true"
                                                                          class="collidable"
                                                                          roughness="0.6" metalness="0.5" shader="standard"             
                                                                          scale="10 10 10"
                                                                          sound="src: #metalcrash; on: click" 
                                                                          animation="property: rotation; from:0 -10 0; to:0 10 0; dur: 1500;  dir:alternate; loop: true;autoplay: true; "> 
                                                        <a-text  position="0 0 0.05" :value="taporclick + ' here to show your favorites'" 
                                                      font="/fonts/nunito/Nunito-Regular.json" shader="msdf" scale="0.9 0.9 0.9" text="width: 1; wrapCount: 14; align: center" ></a-text>
                                                      </a-image>

                                          <a-entity v-if="showFaves" 
                                          animation="property: position; from:5 -5.5 -10; to:5 0.5 -10; dur: 1500;  autoplay: true; ">
<!-- <a-entity v-for="(pic, index) in people.faves.slice(0, 15)" :key="pic.id"> -->
                                                    <a-entity v-for="(pic, index) in people.faves" :key="pic.id">
                                                                  <a-entity position="7.5 0.5 -10" >
                                                                <!-- pic title -->
                                                                <!-- TOOK THIS OUT AS HAVING ALL FACE THE VIEWER, POSSIBLY PUT BACK IN -->
                                                            <!-- <a-entity :position="imagePositionsArray[index]" :rotation="imageRotationArray[index]" id="imageEntity"> -->
                                                              <a-entity :position="imagePositionsArray[index]" rotation="0 0 0" id="imageEntity">
                                                                <!-- <a-text :value="pic.title" position="2 0 0.2">
                                                                   
                                                               </a-text>  -->
                                                               <!-- Information box and description for each image -->
                                                               <a-box v-if="!vrheadset" id="notsure" depth="0.01" rotation="0 -25 0"
                                                                      height="2" width="1.5"
                                                                      :position="(pic.originalWidth/1000/2)+1.25 + ' -0.26 0.6'" 
                                                                      roughness="0.6" metalness="0.5"
                                                                      normal-map="#concreten" 
                                                                      normal-texture-repeat="1 1" 
                                                                      normal-scale="1 1"
                                                                      color="#8e8e93"
                                                                      >

                                                                      <!-- color="#da0f41" #8e8e93-->
                                                                          <!-- FAVE BUTTON -->
                                                                          <a-image v-if="user !== null" id="addFaveButton" depth="0.1" rotation="0 0 0"
                                                                          position="-0.6 0.8 0.1'"
                                                                        
                                                                          src="#faveselected" transparent="true"
                                                                          class="collidable"
                                                                          roughness="0.6" metalness="0.5" shader="standard"           
                                                                          scale="0.25 0.25 0.25"
                                                                          sound="src: #metalcrash; on: click" > </a-image>
                                                              
                                                              
                                                                     
                                                                         <a-entity v-if="pic.title"
                                                                          scale="0.4 0.4 0.4"
                                                                          position="0 0.1 0.1" 
                                                                          :text="'font:/fonts/nunito/Nunito-Regular.json; shader: msdf; color:black;  value: ' + pic.title  + '\n ; align:left; xOffset:0; width: 2.8; wrapCount:15;'"
                                                                          ></a-entity>

                                                                          <!-- <a-text color="black"  value="goToSchatzkin" 
                                                                          mylink="href: https://schatzkin.com;"></a-text> -->
                                                                          <!-- LINK BUTTON -->
                                                                            <a-box 
                                                                              scale="0.3 0.3 0.1"
                                                                              position="-0.5 -0.68 0.2" 
                                                                              src="#linkbox"
                                                                              :link="'href:' + pic.galleryURL"
                                                                              class="collidable"
                                                                              >
                                                                                <a-entity 
                                                                                scale="0.8 0.8 0.8"
                                                                                position="2 -0.2 0.4" 
                                                                                :text="'font:/fonts/nunito/Nunito-Regular.json; shader: msdf; color:blue;  value: Go To Original Gallery ; align:left; xOffset:0; width: 3.5; wrapCount:17;'"
                                                                                :link="'href: ' + pic.galleryURL"
                                                                                class="collidable"
                                                                                ></a-entity>
                                                                          </a-box>
                                                                          <!-- end LINK BUTTON -->
                                                                                                                                                    <!-- :mylink="'href:' + pic.picurl" -->

                                                                                                                                                    <!-- text="font:/fonts/nunito/Nunito-Regular.json; shader: msdf; color:white;  value: Link; align:left; xOffset:0; width: 2.8; wrapCount:15;" -->

                                                               </a-box>

                                                                  <a-box v-if="vrheadset"  id="notsure" depth="0.01" rotation="0 -25 0"
                                                                      height="2" width="1.5"
                                                                      :position="(pic.originalWidth/500/2)+1.25 + ' -0.26 0.6'" 
                                                                      roughness="0.6" metalness="0.5"
                                                                      normal-map="#concreten" 
                                                                      normal-texture-repeat="1 1" 
                                                                      normal-scale="1 1"
                                                                      color="#8e8e93">

                                                                         <!-- FAVE BUTTON -->
                                                                          <a-image v-if="user !== null" id="addFaveButton" depth="0.1" rotation="0 0 0"
                                                                          position="-0.6 0.8 0.1'"
                                                                          src="#faveselected" transparent="true"
                                                                          class="collidable"
                                                                          roughness="0.6" metalness="0.5" shader="standard"             
                                                                          scale="0.25 0.25 0.25"
                                                                          sound="src: #metalcrash; on: click" > </a-image>
                                                              
                                                              
                                                                     
                                                                         <a-entity 
                                                                          scale="0.4 0.4 0.4"
                                                                          position="0 -0.1 0.1" 
                                                                          :text="'font:/fonts/nunito/Nunito-Regular.json; shader: msdf; color:black;  value: ' + pic.title + '\n ; align:left; xOffset:0; width: 2.8; wrapCount:15;'"
                                                                          ></a-entity>

                                                                          <!-- LINK BUTTON for VR-->
                                                                          <a-box 
                                                                              scale="0.3 0.3 0.1"
                                                                              position="-0.5 -0.68 0.2" 
                                                                              src="#linkbox"
                                                                              :mylink="'href:' + pic.galleryURL" 
                                                                              class="collidable"
                                                                              >
                                                                                <a-entity 
                                                                                scale="0.8 0.8 0.8"
                                                                                position="2 -0.2 0.4" 
                                                                                :text="'font:/fonts/nunito/Nunito-Regular.json; shader: msdf; color:blue;  value: ' + pic.galleryURL + ' ; align:left; xOffset:0; width: 3.5; wrapCount:17;'"
                                                                                :mylink="'href:' + pic.galleryURL" 
                                                                                class="collidable"
                                                                                ></a-entity>
                                                                          </a-box>
                                                                          <!-- end LINK BUTTON -->
                                                                                       
                                                               </a-box>

                                                                <!-- need to check if logged in to show fave button -->
                                                                <!-- ADD FAVE HERECURRENTLY WORKING ON -->
                                                            

                                                                <!-- <a-box v-if="vrheadset && user !== null" id="addFaveButton" depth="0.1" rotation="0 -25 0"
                                                                      :position="(pic.originalWidth/500/2)+0.8 + ' 0.9 0.2'"
                                                                       :addfave='"txt:" + pic.resizedUrl' src="#fave" class="collidable"
                                                                      
                                                                      normal-scale="3 3" 
                                                                      roughness="0.6" metalness="0.1"            
                                                                      scale="0.5 0.5 0.5"
                                                                      sound="src: #metalcrash; on: click" > </a-box> -->
                                                    
                                                             
                                                                <!-- PICTURE FRAME HERE -->
                                                                <!-- DIFFERENT SIZE FRAME BASED ON HOW BIG THE IMAGE IS, ITS DOUBLED FOR VR -->
                                                                <a-box v-if="!vrheadset "  :width="(pic.originalWidth/1000)*1.1" :height="(pic.originalHeight/1000)*1.1" depth="0.15" position="0 0 -0.25" 
                                                                    shadow="receive: false; cast: true" 
                                                                     material="roughness: 0.6; metalness:0.2; transparent:true; "  opacity="0.2"
                                                                   >
                                                                </a-box>

                                                                
                                                                        <!-- neon filter infron of image -->
                                                                        <!-- <a-box v-if="!vrheadset && neon"  :width="(pic.originalWidth/1000)*1" :height="(pic.originalHeight/1000)*1" depth="0.15" position="0 0 0.05" 
                                                                            shadow="receive: false; cast: true" 
                                                                              opacity="0.2"   color="#F8ECD1"                        
                                                                              material="roughness: 0.4; metalness:0.8;  "     
                                                                          >
                                                                        </a-box> -->

                                                               

                                                            

                                                                

                                                                <a-box v-if="vrheadset" :width="(pic.originalWidth/500)*1.1" :height="(pic.originalHeight/500)*1.1" depth="0.15" 
                                                                :position="'0 '+ pic.originalHeight/2000 +' -0.25'" 
                                                                    shadow="receive: false; cast: true" 
                                                                    material="roughness: 0.6; metalness:0.2; transparent:true; "  opacity="0.2"
                                                                   >
                                                                </a-box>  

                                                             

                                                                <!-- END OF PICTURE FRAME   -->
                                                                
                                                                <a-box v-if="vrheadset" :id="pic.resizedUrl" static-body class="building2 collidable " 
                                                                :position="'0 '+ pic.originalHeight/2000 +' 0'" 
                                                                :src="pic.url" 
                                                                shadow="receive: false; cast: false" shader="flat" crossorigin="anonymous" :width="pic.originalWidth/500" :height="pic.originalHeight/500" style="pointer-events:none"  depth="0.1" donext ></a-box>

                                                                 <a-box v-if="!vrheadset" :id="pic.resizedUrl" static-body class="building2 collidable " position="0 0 0" 
                                                                :src="pic.url" 
                                                                shadow="receive: false; cast: false" shader="flat" crossorigin="anonymous" :width="pic.originalWidth/1000" :height="pic.originalHeight/1000" style="pointer-events:none" depth="0.1"  donext ></a-box>
                                                               
                                                       
                                                                

                                                            </a-entity>  
                                                               <!-- :src="'#image' + index"   -->
                                                            
                                                        </a-entity>
                                                    </a-entity>

                                          </a-entity>
                                          

                                       

                                </a-entity>
              
                                    <!-- COLLECTION OF IMAGES HERE -->

                                   

                            
                              <!-- ceiling    -->
                           <a-box id="woodceiling" shadow="receive: false; cast: true" position="12.5 24.5 -52.5" rotation="-90 0 0" width='50' height='100' depth="0.2" 
                             normal-map="#windownormal"
                            normal-texture-repeat="5 5"
                            normal-scale="3 3"                             
                            material="roughness: 1; metalness:0.5; transparent:true"                  
                            src="#window" repeat="5 10" opacity="1"> </a-box>



                        <!-- leftwall     -->
                         

                            <a-box id="leftwall"  position="-12.5 12.05 -52.5" rotation="0 90 0" width="100" height="25" depth="0.2" 
                             normal-map="#wallmaterialn"
                              normal-texture-repeat="6 1"
                              normal-scale="1 1"                             
                              material="roughness: 1; metalness:0.5; transparent:true;"                  
                              src="#wallmaterial" repeat="6 1" opacity="1" 
                            ></a-box>
                         
                          <!-- rightwall     -->
                        
                               <a-box id="rightwall"  position="37.5 12.05 -52.5" rotation="0 90 0" width="100" height="25" depth="0.2" 
                                normal-map="#wallmaterialn"
                                  normal-texture-repeat="6 1"
                                  normal-scale="1 1"                             
                                  material="roughness: 1; metalness:0.5; transparent:true;"                  
                                  src="#wallmaterial" repeat="6 1" opacity="1" 
                            ></a-box>

                     
                               <!-- backwall     -->
                        

                                  <a-box id="backwall"  position="12.5 12.05 -102.5" rotation="0 0 0" depth="0.2"  geometry="width: 50; height: 25" 
                             normal-map="#wallmaterialn"
                            normal-texture-repeat="4 1"
                            normal-scale="3 3"                             
                            material=" transparent:true; roughness:1; metalness:0.5;  color:grey "               
                            src="#wallmaterial" repeat="4 1" opacity="1"                            
                             ></a-box>

                 

                    </a-entity>

                    <a-entity  class="building3" position="12.5 0 37.5" rotation="0 180 0">

                                <a-entity class="collection3" v-if="featuredgallery" position="0 0 -10" rotation="0 0 0">
                                  
                                    <a-box id="info" mixin="banner"  position="12.5 13.35 -10" rotation="" width="20" height="5" depth="0.2">
                                            <a-text  position="-8 0.8 1" wrap-count="20" value="Independent Artist Of The Week" scale="3 3 3" ></a-text>
                                            <a-text  position="-8 -1.4 1" :value="featuredgallery.userName + ' - ' + featuredgallery.title"  scale="3 3 3" ></a-text>
                                    </a-box>

                                             <a-box  id="showArtistButton" depth="0.1" rotation="0 0 0"
                                                                          position="12.5 5 1'"
                                                                          showartistcomponent 
                                                                          color="#da0f41"
                                                                          class="collidable"
                                                                          roughness="0.6" metalness="0.1" shader="standard"             
                                                                          scale="10 10 10"
                                                                          sound="src: #metalcrash; on: click" 
                                                                          animation="property: rotation; from:0 -10 0; to:0 10 0; dur: 1500;  dir:alternate; loop: true;autoplay: true; "> 
                                                                           
                                                      <a-text  position="0 0 0.1" :value="taporclick + ' here to see the collection' " 
                                                      font="/fonts/nunito/Nunito-Regular.json" shader="msdf" scale="1 1 1" text="width: 1; wrapCount: 14; align: center; " ></a-text>
                                            </a-box>

                                                     <a-entity v-if="showArtist" 
                                          animation="property: position; from:5 -5.5 -10; to:5 0.5 -10; dur: 1500;  autoplay: true; ">
<!-- <a-entity v-for="(pic, index) in people.faves.slice(0, 15)" :key="pic.id"> -->
                                                    <a-entity v-for="(pic, index) in featuredgallery.pics" :key="pic.id">
                                                                  <a-entity position="7.5 0.5 -10" >
                                                                <!-- pic title -->
                                                                <!-- TOOK THIS OUT AS HAVING ALL FACE THE VIEWER, POSSIBLY PUT BACK IN -->
                                                            <!-- <a-entity :position="imagePositionsArray[index]" :rotation="imageRotationArray[index]" id="imageEntity"> -->
                                                              <a-entity :position="imagePositionsArray[index]" rotation="0 0 0" id="imageEntity">
                                                                <!-- <a-text :value="pic.title" position="2 0 0.2">
                                                                   
                                                               </a-text>  -->
                                                               <!-- Information box and description for each image -->
                                                               <a-box v-if="!vrheadset" id="notsure" depth="0.01" rotation="0 -25 0"
                                                                      height="2" width="1.5"
                                                                      :position="(pic.originalWidth/1000/2)+1.25 + ' -0.26 0.6'" 
                                                                      roughness="0.6" metalness="0.5"
                                                                      normal-map="#concreten" 
                                                                      normal-texture-repeat="1 1" 
                                                                      normal-scale="1 1"
                                                                      color="#8e8e93"
                                                                      >

                                                                      <!-- color="#da0f41" #8e8e93-->
                                                                          <!-- FAVE BUTTON -->
                                                                          <a-image v-if="user !== null" id="addFaveButton" depth="0.1" rotation="0 0 0"
                                                                          position="-0.6 0.8 0.1'"
                                                                          :addfave='"url:" + pic.resizedUrl + ";" + "thumbnailUrl:" + pic.thumbnailUrl + ";"+ "width:" + pic.originalWidth + ";" + "height:" + pic.originalHeight + ";" + "title:" + pic.title'
                                                                          src="#faveselected" transparent="true"
                                                                          class="collidable"
                                                                          roughness="0.6" metalness="0.5" shader="standard"           
                                                                          scale="0.25 0.25 0.25"
                                                                          sound="src: #metalcrash; on: click" > </a-image>
                                                              
                                                              
                                                                     
                                                                         <a-entity v-if="pic.title"
                                                                          scale="0.4 0.4 0.4"
                                                                          position="0 0.1 0.1" 
                                                                          :text="'font:/fonts/nunito/Nunito-Regular.json; shader: msdf; color:black;  value: ' + pic.title  + '\n ; align:left; xOffset:0; width: 2.8; wrapCount:15;'"
                                                                          ></a-entity>

                                                                          <!-- <a-text color="black"  value="goToSchatzkin" 
                                                                          mylink="href: https://schatzkin.com;"></a-text> -->
                                                                          <!-- LINK BUTTON -->
                                                                            <a-box 
                                                                              scale="0.3 0.3 0.1"
                                                                              position="-0.5 -0.68 0.2" 
                                                                              src="#linkbox"
                                                                              :link="'href:https://instant.gallery/g/' + featuredgallery.userName + '-' + featuredgallery.title"
                                                                              class="collidable"
                                                                              >
                                                                                <a-entity 
                                                                                scale="0.8 0.8 0.8"
                                                                                position="2 -0.2 0.4" 
                                                                                :text="'font:/fonts/nunito/Nunito-Regular.json; shader: msdf; color:blue;  value: Go To Original Gallery ; align:left; xOffset:0; width: 3.5; wrapCount:17;'"
                                                                                :link="'href:https://instant.gallery/g/' + featuredgallery.userName + '-' + featuredgallery.title"
                                                                                class="collidable"
                                                                                ></a-entity>
                                                                          </a-box>
                                                                          <!-- end LINK BUTTON -->
                                                                                                                                                    <!-- :mylink="'href:' + pic.picurl" -->

                                                                                                                                                    <!-- text="font:/fonts/nunito/Nunito-Regular.json; shader: msdf; color:white;  value: Link; align:left; xOffset:0; width: 2.8; wrapCount:15;" -->

                                                               </a-box>

                                                                  <a-box v-if="vrheadset"  id="notsure" depth="0.01" rotation="0 -25 0"
                                                                      height="2" width="1.5"
                                                                      :position="(pic.originalWidth/500/2)+1.25 + ' -0.26 0.6'" 
                                                                      roughness="0.6" metalness="0.5"
                                                                      normal-map="#concreten" 
                                                                      normal-texture-repeat="1 1" 
                                                                      normal-scale="1 1"
                                                                      color="#8e8e93">

                                                                         <!-- FAVE BUTTON -->
                                                                          <a-image v-if="user !== null" id="addFaveButton" depth="0.1" rotation="0 0 0"
                                                                          position="-0.6 0.8 0.1'"
                                                                          :addfave='"url:" + pic.resizedUrl + ";" + "thumbnailUrl:" + pic.thumbnailUrl + ";"+ "width:" + pic.originalWidth + ";" + "height:" + pic.originalHeight + ";" + "title:" + pic.title'
                                                                          src="#faveselected" transparent="true"
                                                                          class="collidable"
                                                                          roughness="0.6" metalness="0.5" shader="standard"             
                                                                          scale="0.25 0.25 0.25"
                                                                          sound="src: #metalcrash; on: click" > </a-image>
                                                              
                                                              
                                                                     
                                                                         <a-entity 
                                                                          scale="0.4 0.4 0.4"
                                                                          position="0 -0.1 0.1" 
                                                                          :text="'font:/fonts/nunito/Nunito-Regular.json; shader: msdf; color:black;  value: ' + pic.title + '\n ; align:left; xOffset:0; width: 2.8; wrapCount:15;'"
                                                                          ></a-entity>

                                                                          <!-- LINK BUTTON for VR-->
                                                                          <a-box 
                                                                              scale="0.3 0.3 0.1"
                                                                              position="-0.5 -0.68 0.2" 
                                                                              src="#linkbox"
                                                                             
                                                                               :link="'href:https://instant.gallery/g/' + featuredgallery.userName + '-' + featuredgallery.title"
                                                                              class="collidable"
                                                                              >
                                                                                <a-entity 
                                                                                scale="0.8 0.8 0.8"
                                                                                position="2 -0.2 0.4" 
                                                                                :text="'font:/fonts/nunito/Nunito-Regular.json; shader: msdf; color:blue;  value: ' + pic.galleryURL + ' ; align:left; xOffset:0; width: 3.5; wrapCount:17;'"
                                                                                 :link="'href:https://instant.gallery/g/' + featuredgallery.userName + '-' + featuredgallery.title"
                                                                                class="collidable"
                                                                                ></a-entity>
                                                                          </a-box>
                                                                          <!-- end LINK BUTTON -->
                                                                                       
                                                               </a-box>

                                                                <!-- need to check if logged in to show fave button -->
                                                                <!-- ADD FAVE HERECURRENTLY WORKING ON -->
                                                            

                                                                <!-- <a-box v-if="vrheadset && user !== null" id="addFaveButton" depth="0.1" rotation="0 -25 0"
                                                                      :position="(pic.originalWidth/500/2)+0.8 + ' 0.9 0.2'"
                                                                       :addfave='"txt:" + pic.resizedUrl' src="#fave" class="collidable"
                                                                      
                                                                      normal-scale="3 3" 
                                                                      roughness="0.6" metalness="0.1"            
                                                                      scale="0.5 0.5 0.5"
                                                                      sound="src: #metalcrash; on: click" > </a-box> -->
                                                    
                                                             
                                                                <!-- PICTURE FRAME HERE -->
                                                                <!-- DIFFERENT SIZE FRAME BASED ON HOW BIG THE IMAGE IS, ITS DOUBLED FOR VR -->
                                                                <a-box v-if="!vrheadset "  :width="(pic.originalWidth/1000)*1.1" :height="(pic.originalHeight/1000)*1.1" depth="0.15" position="0 0 -0.25" 
                                                                    shadow="receive: false; cast: true" 
                                                                     material="roughness: 0.6; metalness:0.2; transparent:true; "  opacity="0.2"
                                                                   >
                                                                </a-box>

                                                                
                                                                        <!-- neon filter infron of image -->
                                                                        <!-- <a-box v-if="!vrheadset && neon"  :width="(pic.originalWidth/1000)*1" :height="(pic.originalHeight/1000)*1" depth="0.15" position="0 0 0.05" 
                                                                            shadow="receive: false; cast: true" 
                                                                              opacity="0.2"   color="#F8ECD1"                        
                                                                              material="roughness: 0.4; metalness:0.8;  "     
                                                                          >
                                                                        </a-box> -->

                                                               

                                                            

                                                                

                                                                <a-box v-if="vrheadset" :width="(pic.originalWidth/500)*1.1" :height="(pic.originalHeight/500)*1.1" depth="0.15" 
                                                                :position="'0 '+ pic.originalHeight/2000 +' -0.25'" 
                                                                    shadow="receive: false; cast: true" 
                                                                    material="roughness: 0.6; metalness:0.2; transparent:true; "  opacity="0.2"
                                                                   >
                                                                </a-box>  

                                                             

                                                                <!-- END OF PICTURE FRAME   -->
                                                                
                                                                <a-box v-if="vrheadset" :id="pic.resizedUrl" static-body class="building3 collidable" 
                                                                :position="'0 '+ pic.originalHeight/2000 +' 0'" 
                                                                :src="pic.resizedUrl" 
                                                                shadow="receive: false; cast: false" shader="flat" crossorigin="anonymous" :width="pic.originalWidth/500" :height="pic.originalHeight/500" style="pointer-events:none"  depth="0.1" donext ></a-box>

                                                                 <a-box v-if="!vrheadset" :id="pic.resizedUrl" static-body class="building3 collidable" position="0 0 0" 
                                                                :src="pic.resizedUrl" 
                                                                shadow="receive: false; cast: false" shader="flat" crossorigin="anonymous" :width="pic.originalWidth/1000" :height="pic.originalHeight/1000" style="pointer-events:none" depth="0.1"  donext ></a-box>
                                                               
                                                       
                                                                

                                                            </a-entity>  
                                                               <!-- :src="'#image' + index"   -->
                                                            
                                                        </a-entity>
                                                    </a-entity>

                                          </a-entity>
                                          

                                    
                                    <!-- load our imagee here -->
                                  
                                            <!-- load our imagee here -->
                                           
                                   
                                          

                                </a-entity>
              
                                   
                              <!-- ceiling    -->
                           <a-box id="woodceiling" shadow="receive: false; cast: true" position="12.5 24.5 -52.5" rotation="-90 0 0" width='50' height='100' depth="0.2" 
                             normal-map="#windownormal"
                            normal-texture-repeat="5 5"
                            normal-scale="3 3"                             
                            material="roughness: 1; metalness:0.5; transparent:true"                  
                            src="#window" repeat="5 10" opacity="1"> </a-box>



                        <!-- leftwall     -->
                         

                            <a-box id="leftwall"  position="-12.5 12.05 -52.5" rotation="0 90 0" width="100" height="25" depth="0.2" 
                             normal-map="#wallmaterialn"
                              normal-texture-repeat="6 1"
                              normal-scale="1 1"                             
                              material="roughness: 1; metalness:0.5; transparent:true;"                  
                              src="#wallmaterial" repeat="6 1" opacity="1" 
                            ></a-box>
                         
                          <!-- rightwall     -->
                        
                               <a-box id="rightwall"  position="37.5 12.05 -52.5" rotation="0 90 0" width="100" height="25" depth="0.2" 
                                normal-map="#wallmaterialn"
                                  normal-texture-repeat="6 1"
                                  normal-scale="1 1"                             
                                  material="roughness: 1; metalness:0.5; transparent:true;"                  
                                  src="#wallmaterial" repeat="6 1" opacity="1" 
                            ></a-box>

                     
                               <!-- backwall     -->
                        

                                  <a-box id="backwall"  position="12.5 12.05 -102.5" rotation="0 0 0" depth="0.2"  geometry="width: 50; height: 25" 
                             normal-map="#wallmaterialn"
                            normal-texture-repeat="4 1"
                            normal-scale="3 3"                             
                            material=" transparent:true; roughness:1; metalness:0.5;  color:grey "               
                            src="#wallmaterial" repeat="4 1" opacity="1"                            
                             ></a-box>
                 

                    </a-entity>

                       <a-entity  class="building4" position="-22.5 0 27.5" rotation="0 90 0">

                                  <a-entity class="collection1" position="0 0 -10" rotation="0 0 0">
                                  
                                    <a-box id="info" mixin="banner"  position="12.5 13.35 -10" rotation="" width="20" height="5" depth="0.2">
                                            <a-text  position="-8 1 1" value="Instant.Gallery News" scale="7 7 7" ></a-text>
                                            <a-text  position="-8 -1 1" value="(WIP, you're not meant to be seeing this!)" scale="3 3 3" ></a-text>
                                    </a-box>
                                    <!-- load our imagee here -->
                                  

                                </a-entity>
              
                                   
                              <!-- ceiling    -->
                           <!-- <a-box id="woodceiling" shadow="receive: false; cast: true" position="12.5 24.5 -52.5" rotation="-90 0 0" width='50' height='100' depth="0.2" 
                             normal-map="#windownormal"
                            normal-texture-repeat="5 5"
                            normal-scale="3 3"                             
                            material="roughness: 0.6; metalness:0.5; transparent:true"                  
                            src="#window" repeat="5 10" opacity="1"> </a-box> -->



                        <!-- leftwall     -->
                         

                            <!-- <a-box id="leftwall"  position="-12.5 12 -52.5" rotation="0 90 0" width="100" height="25" depth="0.2" 
                             normal-map="#wallmaterialn"
                              normal-texture-repeat="6 1"
                              normal-scale="1 1"                             
                              material="roughness: 0.6; metalness:0.2; transparent:true;"                  
                              src="#wallmaterial" repeat="6 1" opacity="1" 
                            ></a-box> -->
                         
                          <!-- rightwall     -->
                        
                               <!-- <a-box id="rightwall"  position="37.5 12 -52.5" rotation="0 90 0" width="100" height="25" depth="0.2" 
                                normal-map="#wallmaterialn"
                                  normal-texture-repeat="6 1"
                                  normal-scale="1 1"                             
                                  material="roughness: 0.6; metalness:0.2; transparent:true;"                  
                                  src="#wallmaterial" repeat="6 1" opacity="1" 
                            ></a-box> -->

                     
                               <!-- backwall     -->
                        

                                  <!-- <a-box id="backwall"  position="12.5 12 -102.5" rotation="0 0 0" depth="0.2"  geometry="width: 50; height: 25" 
                             normal-map="#wallmaterialn"
                            normal-texture-repeat="4 1"
                            normal-scale="3 3"                             
                            material=" transparent:true; roughness:0.6; metalness:0.2;  color:grey "               
                            src="#wallmaterial" repeat="4 1" opacity="1"                            
                             ></a-box> -->

                 

                    </a-entity>


                    

          

                     <!-- camera rig -->
                    <a-entity id="player" rotation="0 0 0" > 

                              <!-- <a-text value="Enter VR " position="-1 1 -1"
                            rotation="0 0 0" scale="1 1 1" align="left" color="#FFFFFF"></a-text> -->
                                       
                    <!-- camera -->
                        <!-- <a-entity id="camera" camera wasd-controls  look-controls position="0 2 0"></a-entity> -->
                        <a-camera id="camera" position="0 2 0"> 
                             <!-- <a-box sharetotwitter id="logo" scale="0.15 0.15 0.15" depth="0.4" height="0.4" width="0.4" position="0 -0.7 -1" sound="src: /sounds/metalcrash.mp3; on: click" src="/images/entervr.png" material="shader:standard; roughness:0.1; metalness:0.1; color:#fff"
                                    animation="property: rotation; to:0 360 0; dir:alternate; loop: true; dur: 10000" class="navigationpoint"></a-box> -->
                                <!-- DARK BACKGROUND WITH INFO FOR VIEWER -->
                                <a-box id="mybackground" gallerytour  position="0 0 -1.4" color="black" width="10" height="10">

                                     
                                                        <a-text id="mybackground1"  position="-0 0.05 1" :value="gallery.title + '\n- ' + gallery.userName " text="width: 1; align: center; wrapCount:20"
                                                font="/fonts/nunito/Nunito-Regular.json" shader="msdf" scale="0.25 0.25 0.25" opacity="0"></a-text>
                                                  <a-box id="logobox" opacity="0.75" color="#666666" position="0 0.4 0.2" src="#logo" width="0.3" height="0.3"
                                                  animation="property: position; to: 0 0 -3; dur: 25000; easing: easeInQuad; loop: false"
                                                  animation__001="property: opacity; from: 0.75; to: 0; dur: 3000; easing: easeInQuad; loop: false"> </a-box>
                                            <a-text v-if="gallery.pics.length > 0" id="mybackground2"  position="-0 -0.05 1" 
                                            :value="'Guided tour about to start.\n\n' + taporclick + ' an image or use the virtual joystick to explore at your own pace.'" 
                                            text="width: 1.8; align: center; wrapCount:28"
                                                font="/fonts/nunito/Nunito-Regular.json" shader="msdf" scale="0.15 0.15 0.15" opacity="0"></a-text>
                                            
                                            <a-text v-if="gallery.pics.length == 0" id="mybackground2"  position="-0 -0.05 1" 
                                            :value="'Gallery created. ' + taporclick + ' the Add Image button to start adding to your gallery!'" 
                                            text="width: 1.8; align: center; wrapCount:28"
                                                font="/fonts/nunito/Nunito-Regular.json" shader="msdf" scale="0.15 0.15 0.15" opacity="0"></a-text>
                                      

                                </a-box>
                            
                        </a-camera>

                        <!-- <a-text position="0 0 1" font="/fonts/nunito/Nunito-Regular.json" shader="msdf" scale="0.25 0.25 0.25" value="80sdsdasdasdasdaads" text="align: center"></a-text> -->

                          <!-- <a-text  position="-8.5 -0.3 1" :value="gallery.title" 
                                            font="/fonts/nunito/Nunito-Regular.json" shader="msdf" scale="6 6 6" ></a-text>
                                            <a-text  position="-8 -2 1.5" :value="gallery.userName" 
                                            font="/fonts/nunito/Nunito-Regular.json" shader="msdf" scale="4 4 4" ></a-text> -->
                        <!-- <a-entity id="camera" camera wasd-controls look-controls position="0 2 -20"></a-entity> -->
                        <!-- hand controls -->
                        <a-entity id="left-hand" sound="src: /sounds/laser.mp3" input-listen 
                         raycaster="far:0.1; showLine: false; objects: .collidable; " haptics="events: triggerdown; dur: 100; force: 0.8" 
                        oculus-touch-controls="hand: left; model:true" blink-controls="snapTurn:false; curveHitColor:white; hitCylinderColor:white">
                         
                         <a-text value="Thumbsticks: Teleport\n'X': Exit VR " position="-0.1 0.08 0"
                            rotation="-90 0 0" scale="0.1 0.1 0.1" align="left" color="#FFFFFF"></a-text>
                        </a-entity>

                        <a-entity id="right-hand" sound="src: /sounds/laser.mp3" input-listen 
                        raycaster="far:0.1; showLine: false; objects: .collidable;"  haptics="events: triggerdown; dur: 100; force: 0.8" 
                        oculus-touch-controls="hand: right; model: true"  
                        blink-controls="snapTurn:false; curveHitColor:white; hitCylinderColor:white">
                              <!-- <a-entity id='rightgun'  gltf-model="/objects/laser/Pistol_1.gltf"  rotation="0 90 0"  position="0 0 0" scale="0.25 0.25 0.25"   >                  
                              </a-entity> -->
                               <!-- <a-text value="'B': Skip Track\n'A':Toggle Explore Tab " position="0 0.08 0"
                              rotation="-90 0 0" scale="0.1 0.1 0.1" align="left" color="#FFFFFF"></a-text> -->
                              <a-text value="Triggers: 'Shoot' interactive elements" position="-0.1 -0.1 -0.1"
                              rotation="-90 0 0" scale="0.1 0.1 0.1" align="left" color="#FFFFFF"></a-text>
                        </a-entity>

                       
                    </a-entity>


                 </a-scene>

            </div>
    


        

   

<!--                 
          <div class="sharing"><p><small><span style="color:white"></span></small><br><br><br>
                <a target="_blank" :href="`https://twitter.com/intent/tweet/?url=${galleryURL}&text=Check out this amazing instant.gallery: ${gallery.title}&hastags=instant.gallery ${gallery.title}`" 
                class="share-btn "><i style="color:white" class="fab fa-twitter-square" ></i></a>
                <a target="_blank" :href="`https://www.facebook.com/sharer/sharer.php?u=${galleryURL}`" 
                class="share-btn "><i style="color:white" class="fab fa-facebook-square" ></i></a>
                <a target="_blank"  :href="`https://reddit.com/submit?url=${galleryURL}&title= Checkout this amazing instant.gallery - ${gallery.title}`" class="share-btn"><i style="color:white" class="fab fa-reddit-square" ></i></a>
             
                <a  target="_blank" :href="`mailto:?subject=<Check out this amazing instant.gallery - ${gallery.title} >&body=${galleryURL}<br><br>It works on the the web, and you use your desktop, mobile, tablet or VR headset to explore.`" class="share-btn"><i style="color:white" class="fas fa-envelope-square" ></i></a></p>
          </div> -->
       <!-- gallery.title -->
          <!-- <a href="https://twitter.com/share?url=http://192.168.1.45:8080/galleries/OnDN0ITT34hSSWlliW1A&text=My Gallery" class="share-btn twitter">Twitter</a> -->
         
            <!-- <button  @click="checkVars()">Check vars</button> -->
            <!-- fas fa-home fa-2x -->
        
        <div id="snackbar3">Last image in gallery, explore and start adding to your favorites!</div>
        <div id="snackbar4">IMAGE ADDED to gallery!!</div>
     </div> 

           
</template>

<script>



import AddImage from '@/components/AddImage.vue'
import useStorage from '@/composables/useStorage'
import useDocument from '@/composables/useDocument'
import useDocumentAddFave from '@/composables/useDocumentAddFave'
// import { projectStorage } from '../firebase/config'
import getDocument from '@/composables/getDocument'
import getPeopleFaves from '@/composables/getPeopleFaves'
import getUser from '@/composables/getUser'
import { computed } from '@vue/reactivity'
import {useRouter } from 'vue-router'
import { timestamp } from '@/firebase/config'

  import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardImg, MDBBtn } from "mdb-vue-ui-kit";

 import useCollection from '@/composables/useCollection';

import { ref } from 'vue'
// require('aframe')
// require('aframe-teleport-controls');
//import ('aframe') 
// import('aframe-particle-system-component')

export default {
    props: ['id', ],
    components: { AddImage, 
     MDBCard,
      MDBCardBody,
      MDBCardTitle,
      MDBCardText,
      MDBCardImg,
      MDBBtn },
    mounted() {
        
      //alert("test1") 
      

    },
    setup(props){
      //console.log(props)
        const {error, document: gallery } = getDocument('galleries', props.id)  
        const {featuredgalleryerror, document: featuredgallery } = getDocument('galleries', 'Emily Gomes-Jubilee')    
        
        //alert(props.id)
         const { user } = getUser()
        const { deleteDoc, updateDoc, incrementLikes, incrementHomePageView } = useDocument('galleries', props.id)
        const {peopleerror, addPerson} = useCollection('people')
        // const { updateFaves } = useDocumentAddFave('people', user.value.uid)
        // const { updateFaves } = useDocumentAddFave('people', user.value.uid)
        // const {errorpeople, document: people } = getPeopleFaves('people', user.value.uid)

        if (user.value != null){
          var { updateFaves } = useDocumentAddFave('people', user.value.uid)
          
          
          
          // check if record for the user. if not create strucutre. if so, just wait for faves to be added
          var {getpeopleerror, document: peoplerecord } = getDocument('people', user.value.uid)
          

          var {errorpeople, document1: people } = getPeopleFaves('people', user.value.uid)
          
          console.log('people.value: ')
          console.log(people)
          console.log('gallery.value: ')
          console.log(gallery)
         
        } else {
          console.log('not logged in')
          
          //const {people} = null;
           
        }

      const addView = async (data) => {
        await incrementHomePageView()
      }

      addView();
     

        const { deleteImage} = useStorage()
        const router = useRouter()

        const pin = ref('')
        const hidePinPad = ref(false)
        const unlocked = ref(false)
        const editMode = ref(false)
        const showFaves = ref(false)
        const showArtist = ref(false)


     

         
        // const testObject = ref(gallery)
        //reminder, first image is rendered from hardcoded above, as it's the cover/main image. could change that by adding +1 to index, and including position to cover imager here
        const imagePositionsArray = [             
                                    "-11 1.5 15", "0 1.5 15", "11 1.5 15", 
                                    "-11 1.5 5",  "0 1.5 5", "11 1.5 5",
                                     "-11 1.5 -5", "0 1.5 -5", "11 1.5 -5", 
                                    "-11 1.5 -15", "0 1.5 -15", "11 1.5 -15",
                                     "-11 1.5 -25", "0 1.5 -25", "11 1.5 -25",
                                     "-11 1.5 -35", "0 1.5 -35", "11 1.5 -35",
                                     "-11 1.5 -45", "0 1.5 -45", "11 1.5 -45",
                                     "-11 1.5 -55", "0 1.5 -55", "11 1.5 -55",
                                       "-11 1.5 -65", "0 1.5 -65", "11 1.5 -65",
                                     "-11 1.5 -75", "0 1.5 -75", "11 1.5 -75",

                                     "-11 1.5 -85",  "0 1.5 -85", "11 1.5 -85",
                                     "-11 1.5 -95", "0 1.5 -95",  "11 1.5 -95",
                                       "-11 1.5 -105", "0 1.5 -105", "11 1.5 -105",
                                     "-11 1.5 -115", "0 1.5 -115", "11 1.5 -115",
                                       "-11 1.5 -125", "0 1.5 -125", "11 1.5 -125",
                                     "-11 1.5 -135", "0 1.5 -135", "11 1.5 -135",
                                       "-11 1.5 -145", "0 1.5 -145", "11 1.5 -145",
                                     "-11 1.5 -155", "0 1.5 -155", "11 1.5 -155",
                                       "-11 1.5 -165", "0 1.5 -165", "11 1.5 -165",
                                     "-11 1.5 -175", "0 1.5 -175", "11 1.5 -175",
                                       "-11 1.5 -185", "0 1.5 -185", "11 1.5 -185",
                                     "-11 1.5 -195", "0 1.5 -195", "11 1.5 -195"



                                ]
          //spacing out the VR images a bit more, other than X value, identical to above
            const imagePositionsArrayVR = [             
                                    "-15 1.5 15", "0 1.5 15", "15 1.5 15", 
                                    "-15 1.5 5",  "0 1.5 5", "15 1.5 5",
                                     "-15 1.5 -5", "0 1.5 -5", "15 1.5 -5", 
                                    "-15 1.5 -15", "0 1.5 -15", "15 1.5 -15",
                                     "-15 1.5 -25", "0 1.5 -25", "15 1.5 -25",
                                     "-15 1.5 -35", "0 1.5 -35", "15 1.5 -35",
                                     "-15 1.5 -45", "0 1.5 -45", "15 1.5 -45",
                                     "-15 1.5 -55", "0 1.5 -55", "15 1.5 -55",
                                       "-15 1.5 -65", "0 1.5 -65", "15 1.5 -65",
                                     "-15 1.5 -75", "0 1.5 -75", "15 1.5 -75",

                                     "-15 1.5 -85",  "0 1.5 -85", "15 1.5 -85",
                                     "-15 1.5 -95", "0 1.5 -95",  "15 1.5 -95",
                                       "-15 1.5 -105", "0 1.5 -105", "15 1.5 -105",
                                     "-15 1.5 -115", "0 1.5 -115", "15 1.5 -115",
                                       "-15 1.5 -125", "0 1.5 -125", "15 1.5 -125",
                                     "-15 1.5 -135", "0 1.5 -135", "15 1.5 -135",
                                       "-15 1.5 -145", "0 1.5 -145", "15 1.5 -145",
                                     "-15 1.5 -155", "0 1.5 -155", "15 1.5 -155",
                                       "-15 1.5 -165", "0 1.5 -165", "15 1.5 -165",
                                     "-15 1.5 -175", "0 1.5 -175", "15 1.5 -175",
                                       "-15 1.5 -185", "0 1.5 -185", "15 1.5 -185",
                                     "-15 1.5 -195", "0 1.5 -195", "15 1.5 -195"



                                ]

          //not using this, may end up putting it back in
         const imageRotationArray = [             
                                    "0 0 0", "0 0 0", "0 0 0",
                                    "0 90 0", "0 270 0",
                                     "0 0 0", "0 0 0",
                                    "0 90 0", "0 270 0",
                                     "0 0 0", "0 0 0",
                                      "0 90 0", "0 270 0",
                                     "0 0 0", "0 0 0",
                                    "0 90 0", "0 270 0",
                                     "0 0 0", "0 0 0",
                                      "0 90 0", "0 270 0",
                                     "0 0 0", "0 0 0",
                                    "0 90 0", "0 270 0",
                                     "0 0 0", "0 0 0",
                                      "0 90 0", "0 270 0",
                                     "0 0 0", "0 0 0",
                                    "0 90 0", "0 270 0",   "0 0 0", "0 0 0", 
                                    "0 90 0", "0 270 0",
                                     "0 0 0", "0 0 0",
                                    "0 90 0", "0 270 0",
                                     "0 0 0", "0 0 0",
                                      "0 90 0", "0 270 0",
                                     "0 0 0", "0 0 0",
                                    "0 90 0", "0 270 0",
                                     "0 0 0", "0 0 0",
                                      "0 90 0", "0 270 0",
                                     "0 0 0", "0 0 0",
                                    "0 90 0", "0 270 0",
                                     "0 0 0", "0 0 0",
                                      "0 90 0", "0 270 0",
                                     "0 0 0", "0 0 0",
                                    "0 90 0", "0 270 0"
                                    
                                ]                       

                const imagePositionsArray1 = [             
                                    "-10 4.2 10", "10 4.5 10", 
                                    "-10 4.5 -10", "10 4.5 -10",
                                     "-10 4.5 -30", "10 4.5 -30", 
                                    "-10 4.5 -50", "10 4.5 -50",
                                     "-10 4.5 -70", "10 4.5 -70",

                                     "-23 7.5 0", "23 7.5 0",
                                     "-23 7.5 -40", "23 7.5 -40",
                                     "-23 7.5 -60", "23 7.5 -60",
                                     "23 7.5 -80", "23 7.5 -90",
                                     "-23 7.5 -100", "23 7.5 -100"
                                ]  
                                
         const imageRotationArray1 = [             
                                    "0 0 0", "0 0 0", 
                                    "0 0 0", "0 0 0", 
                                     "0 0 0", "0 0 0",
                                    "0 0 0", "0 0 0", 
                                     "0 0 0", "0 0 0",
                                     "0 90 0", "0 270 0", 
                                    "0 90 0", "0 270 0", 
                                    "0 90 0", "0 270 0", 
                                    "0 90 0", "0 270 0", 
                                     "0 90 0", "0 270 0", 
                                ]  
                                
           const imageScaleDivider1 = [             
                                    "400", "400", 
                                    "400", "400",
                                    "400", "400",
                                    "400", "400",
                                    "400", "400",                                 
                                     "200", "200", 
                                     "200", "200", 
                                     "200", "200", 
                                     "200", "200", 
                                     "200", "200"
           ]
         
        const galleryURL = window.location.href;
        //alert(galleryURL)

        //galleryScheme.value = gallery.value.galleryScheme
        //alert(gallery.value.title)
                                
        //alert('hello');
         console.log("here!")
           

        //  AFRAME.registerComponent('aframeloaded', {
        // init: function () { 
        //     console.log(this.el.renderer);
        //     console.log('aframe loaded!!');
        // }
        // });


        //console.log(user.value.uid)
      
    //   if(!gallery.value==null){
    //       console.log('gallery object loaded')
    //       console.log(gallery.value.title)
    //   } else {
    //     console.log('gallery object not loaded')
    //   }

    // const supportsVR = 'hasPositionalTracking' in navigator;

   
   
        const imagePosition = async (pic) => {
            console.log(pic)
            // const newImagePosition = '"position:' + imagePositionsArray[pic] + '"'
            //let newImagePosition = '"' + imagePositionsArray[pic] + '"'
            const newImagePosition = testPosition
            console.log(newImagePosition)
            return newImagePosition
        }

       
        // if(galleryScheme == "fishing"){
        //     alert('gallery is fishing')
        // } else {
        //     alert('gallery is not fishing')
        // }
    
        //alert("hello")
        // alert(user.value)
        // alert(user)
        console.log(user)
        const ownership = computed (() => {
            return gallery.value && user.value && user.value.uid == gallery.value.userId
        })

        const instant = computed (() => {
          //alert(people.value.testfield)
          // alert(gallery.value.pics)
          // alert(people.value.testfield)
          //alert('hello')
            if(gallery.value.galleryScheme=='instant'){
              // alert(gallery.value.galleryScheme)
              return true
            } else {
              return false
            }
        })

          const moody = computed (() => {
 
            if(gallery.value.galleryScheme=='moody'){
              return true
            } else {
              return false
            }
        })

          const ornate = computed (() => {
          //alert(people.value.testfield)
          // alert(gallery.value.pics)
          // alert(people.value.testfield)
          //alert('hello')
            if(gallery.value.galleryScheme=='ornate'){
              return true
            } else {
              return false
            }
        })

           const retail = computed (() => {
          //alert(people.value.testfield)
          // alert(gallery.value.pics)
          // alert(people.value.testfield)
          //alert('hello')
            if(gallery.value.galleryScheme=='retail'){
              return true
            } else {
              return false
            }
        })

         const neon = computed (() => {
          //alert(people.value.testfield)
          // alert(gallery.value.pics)
          // alert(people.value.testfield)
          //alert('hello')
            if(gallery.value.galleryScheme=='neon'){
              return true
            } else {
              return false
            }
        })

             const halloween = computed (() => {
          //alert(people.value.testfield)
          // alert(gallery.value.pics)
          // alert(people.value.testfield)
          //alert('hello')
            if(gallery.value.galleryScheme=='halloween'){
              return true
            } else {
              return false
            }
        })

             const summer = computed (() => {
          //alert(people.value.testfield)
          // alert(gallery.value.pics)
          // alert(people.value.testfield)
          //alert('hello')
            if(gallery.value.galleryScheme=='summer'){
              return true
            } else {
              return false
            }
        })

                const birthday = computed (() => {
          //alert(people.value.testfield)
          // alert(gallery.value.pics)
          // alert(people.value.testfield)
          //alert('hello')
            if(gallery.value.galleryScheme=='birthday'){
              return true
            } else {
              return false
            }
        })

                     const wedding = computed (() => {
          //alert(people.value.testfield)
          // alert(gallery.value.pics)
          // alert(people.value.testfield)
          //alert('hello')
            if(gallery.value.galleryScheme=='wedding'){
              return true
            } else {
              return false
            }
        })

         const taporclick = computed (() => {
          //alert(people.value.testfield)
          // alert(gallery.value.pics)
          // alert(people.value.testfield)
          //alert('hello')
            if(mobileOwner || mobileNotOwner){
              return 'Tap'
            } else {
              if (vrheadset){
                return "'Shoot'"
              }else {
                return 'Click'
              }
              
            }

            


        })

        
        //  const favedpic = computed (() => {
        //   //alert(people.value.testfield)
        //   // alert(gallery.value.pics)
        //   alert(people.value.faves[0].url)
        //   //alert('hello')


        //         for(var i=0; i < people.value.faves.length; i++){
        //           if( people.value.faves[i] == this.country_name){
        //             return true
        //           }
        //         }

        //     // if(gallery.value.galleryScheme=='wedding'){
        //     //   return true
        //     // } else {
        //     //   return false
        //     // }

        // })
        
    

        const favedpic = function(url) {
          // return `My favourite game is: ${game}.`
          //alert(people.value.faves.length)
          var temploop = people.value.faves.length;
          //console.log(temploop)
          //console.log(url)
                for(var i=0; i < temploop; i++){
                  
                  if( people.value.faves[i].url == url){
                    return true
                  }
                }
          return false
        };

        // const favedpic = async (index) => {
        //     //console.log(people.value.faves.length)
        //     return false
        // }

        // people.value.faves

          const scenefog = computed (() => {
          //alert(people.value.testfield)
          // alert(gallery.value.pics)
          // alert(people.value.testfield)
          //alert('hello')
            if(gallery.value.galleryScheme=='halloween' || gallery.value.galleryScheme=='summer' ){
              return 'fog="type: linear; color: #C1C8E4; far: 50"'
            } else {
              return ""
            }
        })

 

        var pinProvided = false
       
        // if(gallery.value.pin == null){
        //   alert('does this work?')
        // }

        const pinprotected = computed (() => {
          //alert('running pin protected')
          if(gallery.value.pin == null || unlocked.value || gallery.value.sharingOptions!="linkpin"){
            // should be unlocked
            //alert('should be unlocking here!!!')
            // alert('pinProvided is:' + pinProvided)
            return false
          } else {
            //pin protected
            return true
          }
           
        })


        function handlePinCheck(){
            var int1 = parseInt(gallery.value.pin)
            var int2 = parseInt(pin.value)
            //alert(int1)
            //alert(int2)
            if(int1 == int2){
              //alert('should be unlocking here!')
              //pinprotected.value = false;
              pinProvided = true 
              unlocked.value = true
              hidePinPad.value = true
            } else {
              //alert('Incorrect pin. Get the pin from the gallery creator.')
              swal({
                  title: "Incorrect pin. ",
                  text: "Get the pin from the gallery creator.",
                  icon: "error",
                });
            }
        }

      
        const handleDelete = async () => {

            await deleteImage(gallery.value.filePath)
            await deleteDoc()
            router.push({name:'Home'})
        }

        const handlePicDelete = async (id) => {
          //alert('trying to delete image')
            console.log('handling pic delete here')
            const pics = gallery.value.pics.filter((pic) => pic.id != id)
            console.log("gallery pics")
            console.log(gallery.value.pics)
            //await deleteImage(gallery.pics.value.filePath)
            await updateDoc({ pics })

        }

        //var editMode = false
        function handleGalleryEdit(){
          //alert('edit mode')
          //editMode = !editMode

          editMode.value = !editMode.value

          //alert(gallery.value.galleryScheme)

          if(editMode.value){
            // document.getElementById("editGallery").style.opacity = "1";
            //document.getElementById("deleteButton").setAttribute('scale', '1 1 1');
            
            } else {
              // document.getElementById("editGallery").style.opacity = "0.5";
              //document.getElementById("deleteButton").setAttribute('scale', '0 0 0');
            }
          

        }

         const checkVars = async () => {
           console.log('checkvars')
            console.log(gallery.value.title)
        }

       
        // joystick
        //         let joystick1 = new Joystick("stick1", 64, 8);
        // let joystick2 = new Joystick("stick2", 64, 8);

        // function update()
        // {
        //     document.getElementById("status1").innerText = "Joystick 1: " + JSON.stringify(joystick1.value);
        //     document.getElementById("status2").innerText = "Joystick 2: " + JSON.stringify(joystick2.value);
        // }

        // function loop()
        // {
        //     requestAnimationFrame(loop);
        //     update();
        // }

        // loop();

    
        //  gsap.to("np", {duration: 3, scale: 10});


        let headset = AFRAME.utils.device.checkHeadsetConnected();

             var isMobile = false; //initiate as false
                    // device detection
                    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
                        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
                        isMobile = true;
                    }
            
        


            if (isMobile) {
              //alert('mobile')
              //var mobiledevice = true;
                 var mobileOwner = true;
                  var mobileNotOwner = true;
            } else if (headset) {
              //alert('headset')
              var vrheadset = true;
              var vrmode = false;
              
                var headsetOwner = true;
                var headsetNotOwner = true;
              
            } else {
                //alert('desktop')
                var desktopOwner = true;
                var desktopNotOwner = true;
                 
            }
        
  
        // if(gallery.value.galleryScheme=='neon'){
        //   alert('neon')
        // } else {
        //   alert('not neon')
        // }

     
       
            var songNumber = Math.floor(Math.random() * (5 - 1 + 1)) + 1;
            var startingSong =  '/sounds/' + songNumber + '.mp3';

            var neonsongNumber = Math.floor(Math.random() * (2 - 1 + 1)) + 1;
            var neonStartingSong =  '/sounds/' + neonsongNumber + '.mp3';

            var birthdayStartingSong =  '/sounds/happybirthday.mp3';

            var classicalStartingSong =  '/sounds/4.mp3';

         //var vrmode = false;

        // NOTE: toggle for debugging
        // var vrheadset = true;

              //toggle this on/off for testing what it looks like in full VR with headset
        // headset=true
        // vrmode = true;
        // vrheadset = false;


        //  window.addEventListener('enter-vr', e => {
        //         console.log('entered vr1')
        //         if (AFRAME.utils.device.checkHeadsetConnected()) { 
        //           /* modify cursor */ 
        //           vrmode = true;

        //                  delete AFRAME.components['addfave']
   
        //       AFRAME.registerComponent('addfave', {
        //           init: function () {
        //           var self = this;
        //           var scene = self.el.sceneEl;
        //           //var raycaster = scene.querySelector('[cursor]').components.raycaster;
              
        //           // Define function to be executed on click.
        //           var clickHandler = function (e) {
              
        //             console.log('clicked');
        //             // test();
        //             self.el.setAttribute('material', 'color', 'white');
              
              
        //           };
              
        //           // Add event listener for click.
        //           self.el.addEventListener('click', clickHandler);
        //           // self.el.addEventListener('raycaster-intersected', clickHandler);
        //           if(vrmode){
        //             self.el.addEventListener('raycaster-intersected', clickHandler);
        //           }
              
        //           }
        //       });   
        //         }
        //   });

              delete AFRAME.components['addfave']
   
              AFRAME.registerComponent('addfave', {
                  schema: {
                    url: {default:'default1'},
                    width: {default:4000},
                    height: {default:3000},
                    title: {default:''}
                  },
                  init: function () {
                  var self = this;
                  var scene = self.el.sceneEl;
                  var data = this.data;

                  //var raycaster = scene.querySelector('[cursor]').components.raycaster;
              
                  // Define function to be executed on click.
                  var clickHandler = function (e) {
                    //console.log('event intersectiosn details:')
                    //console.log(e.detail.getIntersection(e.target));
                    //console.log(e.detail.els);
                    console.log('clicked');
                    self.el.setAttribute('src', '#faveboxfull');
                    handleAddFave(data);
                    self.el.removeAttribute("animation");

                  };
              
                  // Add event listener for click.
                  self.el.addEventListener('click', clickHandler);
                  //if(vrheadset){
                    //self.el.addEventListener('raycaster-intersected', clickHandler);
                    //console.log(evt.detail.getIntersection(evt.target));
                  //}
              
                  }
              });   

                  
                  const handleAddFave = async (data) => {
                    //alert(people.value.userName)
                    console.log('clicked1');
                    //alert(peoplerecord.value)
                      if(peoplerecord.value != null){
                        //alert('people record exists')
                        //alert(peoplerecord.value)
                      } else {
                        //alert('people record does not exists, lets create entry here')
                          const res = await addPerson({
                            userId: user.value.uid,
                            userName: user.value.displayName,
                            following:[],
                            faves:[],
                            galleries:[]
                          })
                      }


                   const newFave = ({
                    title: data.title,
                    url: data.url,
                    thumbnailUrl: data.thumbnailUrl,
                    originalWidth: data.width,
                    originalHeight: data.height,
                    galleryURL: galleryURL,
                    id: Math.floor(Math.random() * 10000000)
                })

                  //let's create the document here first (or check if created)


                  //then update the faves array
                  //alert(user.value.uid)

                    await updateFaves(user.value.uid, {
                                  faves:[...people.value.faves, newFave]
                   })

                   await incrementLikes('totallikes')
              }



              //code here for HYPERLINKS
              delete AFRAME.components['mylink']
              AFRAME.registerComponent("mylink", {
                schema: {
                    type: {default:'new'}
                 
                  },
                  init: function() {
                      var data = this.data;
                    this.el.addEventListener("click", (e)=> {
                      //  window.location = this.data.href;
                      
                       window.open(this.data.href);
                    })

                    if(vrheadset){
                     
                      this.el.addEventListener("raycaster-intersected", (e)=> {
                        localurl = this.data.href;
                          var myCamera = document.querySelector('#camera');

                  

                      var exitvr = document.createElement('a-image');
                          exitvr.setAttribute("id", "exitvrdialog");
                          exitvr.setAttribute("position", "0 0 -2");
                          exitvr.setAttribute("rotation", "0 0 0");
                          exitvr.setAttribute("scale", "1 1 1");
                          exitvr.setAttribute("src", "/images/exitvr.png");
                          // exitvr.setAttribute("material", "color: #000");
                          // intro2.setAttribute("scene3temp", "");
                          exitvr.setAttribute("class", "");
                          
                          myCamera.appendChild(exitvr);

                        
                
                            // document.querySelector("#exitvr").setAttribute('animation', {
                            //   property: 'material.color',
                            //     dur: 500,
                            //     easing:'easeInSine',
                            //     to: '#fff'      
                            //   });

                                var okexitvr = document.createElement('a-box');
                                okexitvr.setAttribute("id", "exitvr");
                                okexitvr.setAttribute("position", "-0.3 -0.3 -1.6");
                                okexitvr.setAttribute("rotation", "0 -33 0");
                                okexitvr.setAttribute("scale", "0.2 0.2 0.2");
                                okexitvr.setAttribute("src", "/images/ok.png");
                                okexitvr.setAttribute("class", "collidable");
                                okexitvr.setAttribute("exitvrcomponent", "");

                                myCamera.appendChild(okexitvr);

                                 var cancelexitvr = document.createElement('a-box');
                                cancelexitvr.setAttribute("id", "cancelexitvr");
                                cancelexitvr.setAttribute("position", "0.3 -0.3 -1.6");
                                cancelexitvr.setAttribute("rotation", "0 -33 0");
                                cancelexitvr.setAttribute("scale", "0.2 0.2 0.2");
                                cancelexitvr.setAttribute("src", "/images/cancel.png");
                                cancelexitvr.setAttribute("class", "collidable");
                                cancelexitvr.setAttribute("cancelexitvrcomponent", "");
                                // cancelexitvr.setAttribute("material", "color: grey");

                                myCamera.appendChild(cancelexitvr);


                              // document.querySelector("#exitvr").addEventListener('animationcomplete', function() {
                              //       var myScene = document.querySelector('a-scene');
                              //       myScene.exitVR()
                                    
                              //       exitvr.parentNode.removeChild(exitvr);
                              //       window.location.href = localurl;
                                    
                              // });

                              

                      
                      })
                    }

                
                  }
                })


                   const showFavesButton = async () => {

                        showFaves.value = true
                    }
                        delete AFRAME.components['showfavescomponent']
                        AFRAME.registerComponent('showfavescomponent', {
                            init: function(){
                                    var el = this.el;
                                    //var data = this.data;
                                
                                    // el.addEventListener('click', favesclicked, false)

                                    el.addEventListener('click', function () {            
                                      //alert('show faves was clikced')
                                      showFaves.value = true

                                      var showFavesButton = document.getElementById('showFavesButton')
                                       	showFavesButton.setAttribute('animation__001', {
                                          property: 'position',
                                          dur: 1500,
                                          from: {x: 12.5, y: 5, z: 1},
                                          to: {x: 12.5, y: -10, z: 1}
                                      });
                                      //favesClicked()
                                      });     

                                       if(vrheadset){
                                          // self.el.addEventListener('raycaster-intersected', clickHandler);

                                                el.addEventListener('raycaster-intersected', function () {            
                                            //alert('show faves was clikced')
                                            showFaves.value = true

                                            var showFavesButton = document.getElementById('showFavesButton')
                                              showFavesButton.setAttribute('animation__001', {
                                                property: 'position',
                                                dur: 1500,
                                                from: {x: 12.5, y: 5, z: 1},
                                                to: {x: 12.5, y: -10, z: 1}
                                            });
                                            //favesClicked()
                                            });  
                                        }
                                  
                        
                                    // function favesclicked(evt){
                                    //     //positionmove()
                                    //     alert('showfaves was clicked')
                                    // }
                        
                                    // function positionmove(){
                                    //     alert('I was clicked')
                                    // }
                        
                                
                                }
                        });

                           const showArtistButton = async () => {

                            showArtist.value = true
                          }

                         delete AFRAME.components['showartistcomponent']
                        AFRAME.registerComponent('showartistcomponent', {
                            init: function(){
                                    var el = this.el;
                                    //var data = this.data;
                                
                                    // el.addEventListener('click', favesclicked, false)

                                    el.addEventListener('click', function () {            
                                      //alert('show faves was clikced')
                                      showArtist.value = true

                                      var showArtistButton = document.getElementById('showArtistButton')
                                       	showArtistButton.setAttribute('animation__001', {
                                          property: 'position',
                                          dur: 1500,
                                          from: {x: 12.5, y: 5, z: 1},
                                          to: {x: 12.5, y: -10, z: 1}
                                      });
                                      //favesClicked()
                                      });     


                                    
                                           if(vrheadset){
                                          // self.el.addEventListener('raycaster-intersected', clickHandler);

                                            el.addEventListener('raycaster-intersected', function () {            
                                              //alert('show faves was clikced')
                                              showArtist.value = true

                                              var showArtistButton = document.getElementById('showArtistButton')
                                                showArtistButton.setAttribute('animation__001', {
                                                  property: 'position',
                                                  dur: 1500,
                                                  from: {x: 12.5, y: 5, z: 1},
                                                  to: {x: 12.5, y: -10, z: 1}
                                              });
                                              //favesClicked()
                                              });     
                                        }
                                  
                        
                                    // function favesclicked(evt){
                                    //     //positionmove()
                                    //     alert('showfaves was clicked')
                                    // }
                        
                                    // function positionmove(){
                                    //     alert('I was clicked')
                                    // }
                        
                                
                                }
                        });


              
                          //SETUPSCENE!!!
                          delete AFRAME.components['setup-scene']
                          AFRAME.registerComponent('setup-scene',{
                                    tick: function () {
                                        // this.doSomething();
                                        // if camera position x = ....
                                        },
                                  init: function(){
                                    var myScene = document.querySelector('a-scene');

                                      //alert("setting up scene");
                                          // var myLaser = document.createElement('a-entity');
                                        // 		myLaser.setAttribute("id", "myLaser");
                                        // 		myLaser.setAttribute("laser-controls", "");
                                        // 		myLaser.setAttribute('line', {
                                          // 			color: '#D60057',
                                          // 			opacity: 0.5
                                          // 		});
                                          // 	myScene.appendChild(myLaser);

                                                  var headset = AFRAME.utils.device.checkHeadsetConnected();
                                                  //let mobile = AFRAME.utils.device.isMobile();

                                                  var isMobile = false; //initiate as false
                                                    // device detection
                                                    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
                                                        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
                                                        isMobile = true;
                                                    }
                                                //   alert(headset)
                                                //   alert(mobile)
                                                // const headset1 = document.getElementById("headset1")
                                                // const headset2 = document.getElementById("headset2")
                                                // const desktop1 = document.getElementById("desktop1")
                                                // const desktop2 = document.getElementById("desktop2")
                                                // const mobile1 = document.getElementById("mobile1")
                                                // const mobile2 = document.getElementById("mobile2")

                                                  
                                                  if (isMobile) {
                                                      //alert("mobile text showing")   
                                                  
                                                    // mobile1.setAttribute("visible", "true")
                                                    // mobile2.setAttribute("visible", "true")
                                                    // desktop1.setAttribute("visible", "false")
                                                    // desktop2.setAttribute("visible", "false")
                                                    //alert("only mobile text should now be showing")   

                                                      const vrbuttons = document.getElementsByClassName('a-enter-vr');
                                                      for (const vrbutton of vrbuttons){
                                                          vrbutton.style.display = 'none';
                                                          
                                                      }
                                                    // const vrbuttons = document.getElementsByClassName('a-enter-vr');
                                                      // for (const vrbutton of vrbuttons){
                                                      //     vrbutton.style.display = 'none';
                                                      // }
                                                  } else if (headset) {
                                                    fullvr = true;
                                                    //alert('headset connected!')
                                                    // const headsetInVROwner = document.getElementById("headsetInVROwner")
                                                    // const headsetInVRNotOwner = document.getElementById("headsetInVRNotOwner")
                                                    
                                                    // headsetInVROwner.setAttribute("src", "true")
                                                    // headsetInVRNotOwner.setAttribute("src", "true")
                                                          // alert("full VR supported"), show show VR  
                                                      // const vrbuttons = document.getElementsByClassName('a-enter-vr');
                                                      // for (const vrbutton of vrbuttons){
                                                      //     vrbutton.style.display = 'none';
                                                      // }                      
                                                } else if (!headset && !isMobile){
                                                    //alert("desktop text showing")   
                                                        // desktop1.setAttribute("visible", "true")
                                                        // desktop2.setAttribute("visible", "true")
                                                        // mobile1.setAttribute("visible", "false")
                                                        // mobile2.setAttribute("visible", "false")
                                                    }
                                                  
                                                  

                                              
                                                //   if (mobile) {
                                                //       // mobile, dont show VR buttons
                                                //     //   const ismobile = true
                                                //     const mobile1 = document.getElementById("mobile1")
                                                //     const mobile2 = document.getElementById("mobile2")
                                                //     mobile1.setAttribute("visible", "true")
                                                //     mobile2.setAttribute("visible", "true")

                                                //       const vrbuttons = document.getElementsByClassName('a-enter-vr');
                                                //       for (const vrbutton of vrbuttons){
                                                //           vrbutton.style.display = 'none';
                                                          
                                                //       }

                                                //   }
                                                //   else {
                                                //       //not a mobile                   
                                                    
                                                //  }

                                                // isometric view for camera
                                                var camera = document.getElementById('camera')
                                                document.querySelector("#camera").object3D.position.set(-20, 10, -5);
                                                camera.components["look-controls"].yawObject.rotation.y = -0.7

                                                //START AUTO TOUR
                                                //var currentlyshowing = 0;

                                                // for (let i = 0; i < globalImagePositionsArray.length; i++) {
                                                //     var stringArray = globalImagePositionsArray[i].split(/(\s+)/);
                                                //     //camera.setAttribute('position', {x: 0+ 4.5, y: 0 + 2, z: 0 + actualPosition.z}); 
                                                //     //camera.components["look-controls"].yawObject.rotation.y = 1.57
                                                //     console.log(stringArray[0])
                                                //     console.log(stringArray[2])
                                                //     console.log(stringArray[4])
                                                //                  camera.setAttribute('animation', {
                                                //                     property: 'position',
                                                //                     dur: 2500,
                                                //                     easing:'easeInSine',
                                                //                     to: {x: stringArray[0]}
                                                //                 });
                                    
                                                //                 camera.setAttribute('animation', {
                                                //                     property: 'position',
                                                //                     dur: 2500,
                                                //                     easing:'easeInSine',
                                                //                     to: {y: 2}
                                                //                 });
                                    
                                                //                 camera.setAttribute('animation', {
                                                //                     property: 'position',
                                                //                     dur: 2500,
                                                //                     easing:'easeInSine',
                                                //                     to: {z: stringArray[4] - 25}
                                                //                 });
                                    
                                                //                  camera.setAttribute('animation__1', {
                                                //                     property: 'rotation',
                                                //                     dur: 2500,
                                                //                     easing:'easeInSine',
                                                //                     to: {y: 0}
                                                //                 });
                                    
                                                //         camera.components["look-controls"].yawObject.rotation.y = 0


                                                // }
                                                
                                              document.querySelector('a-scene').addEventListener('enter-vr', function () {
                                            console.log("ENTERED VR");

                                                                    
                                                        var tempel = document.querySelector('#soundBox');
                                                        tempel.components.sound.playSound();
                                                        tempel.setAttribute('src', '/images/music.png')
                                                        soundstarted=true;

                                                        vrmode = true;
                                                        console.log(vrmode)
                                                          //   AFRAME.components['donext'].addEventListener('raycaster-intersected', imageclicked)
                                                          // AFRAME.components['addfave'].addEventListener('raycaster-intersected', clickHandler);
                                                  
                                                    if(headset){
                                                      
                                                        // vrmode = true;
                                                        // console.log(vrmode)
                                                        // AFRAME.components['donext'].addEventListener('raycaster-intersected', imageclicked)
                                                        //   AFRAME.components['addfave'].addEventListener('raycaster-intersected', clickHandler);
                                                          // showfavescomponent
                                                        if(document.getElementById('headsetOwner') !=null ){
                                                            const headsetOwner = document.getElementById("headsetOwner")
                                                            headsetOwner.setAttribute("src", "/images/instructions-HIVR-O.png")
                                                        }

                                                    
                                                        if(document.getElementById('headsetNotOwner') !=null ){
                                                            const headsetNotOwner = document.getElementById("headsetNotOwner")
                                                            headsetNotOwner.setAttribute("src", "/images/instructions-HIVR-NO.png")
                                                        }
                                                    }
                                                    
                                        });


                                                        document.querySelector('a-scene').addEventListener('exit-vr', function () {
                                                            //console.log("exit VR");

                                                              var camera = document.getElementById('camera')
                                                              document.querySelector("#camera").object3D.position.set(-20, 10, -5);
                                                              camera.components["look-controls"].yawObject.rotation.y = -0.7
                                                               document.querySelector("#camera").setAttribute('rotation', '0 0 0')

                                                           
                                                            if(soundstarted){                
                                                                var tempel = document.querySelector('#soundBox');
                                                                tempel.components.sound.pauseSound();
                                                                tempel.setAttribute('src', '/images/musicoff.png')
                                                            } 
                                                        });
                                  }
                                });
                                //END OF SETUPSCENE

                                    //AUTO TOUR CODE STARTS HERE. TAKE OUT 'gallerytour' from component for manual explore'
                                    delete AFRAME.components['gallerytour']
                                    AFRAME.registerComponent('gallerytour', {
                                    init: function(){
                                            var el = this.el;
                                            //var data = this.data;

                                            //var myBackground= document.querySelector('#darkBackground');
                                            //myVid.components.material.material.map.image.pause();

                                            // document.querySelector("#mybackground1").setAttribute('animation', {
                                            //      property: 'material.opacity',
                                            //       dir:'alternate',
                                            //       dur: 500,
                                            //       delay:2000,
                                            //       easing:'easeInSine',
                                            //       from:'1',
                                            //       to: '0'      
                                            //     });

                                            // if(gallery.pics) {
                                            //     alert(gallery.pics.length)
                                            // }

                                            document.querySelector("#mybackground1").setAttribute('animation', {
                                                property: 'opacity',
                                                dir:'alternate',
                                                dur: 500,
                                                delay:500,
                                                easing:'easeInSine',
                                                from:'0',
                                                to: '1'      
                                              });

                                              document.querySelector("#mybackground1").setAttribute('animation__1', {
                                                property: 'opacity',
                                                dir:'alternate',
                                                dur: 500,
                                                delay:4000,
                                                easing:'easeInSine',
                                                from:'1',
                                                to: '0'      
                                              });

                                              document.querySelector("#mybackground2").setAttribute('animation', {
                                                property: 'opacity',
                                                dir:'alternate',
                                                dur: 500,
                                                delay:1000,
                                                easing:'easeInSine',
                                                from:'0',
                                                to: '1'      
                                              });

                                              document.querySelector("#mybackground2").setAttribute('animation__2', {
                                                property: 'opacity',
                                                dir:'alternate',
                                                dur: 500,
                                                delay:4000,
                                                easing:'easeInSine',
                                                from:'1',
                                                to: '0'      
                                              });

                                              document.querySelector("#mybackground").setAttribute('animation__07', {
                                                property: 'opacity',
                                                dur: 1000,
                                                delay:4100,
                                                easing:'easeInSine',
                                                from:'1',
                                                to: '0'      
                                              });

                                              

                                              
                      
                                              document.querySelector("#mybackground").addEventListener('animationcomplete', function(e) {
                                                //alert(e.detail.name)
                                                    if(e.detail.name == 'animation__07'){
                                                         console.log('seeing this just once?')
                                                        nextPositioninTour(0)
                                                          //  var camera = document.getElementById('camera')
                                                          //   camera.emit("first", null, false);
                                                          //   camera.emit("second", null, false);
                                                          //   camera.emit("third", null, false);
                                                          //   camera.emit("fourth", 'lastone', false);
                                                          //   camera.components["look-controls"].yawObject.rotation.y = 0
                                                        var mybackground = document.getElementById('mybackground');
                                                        mybackground.parentNode.removeChild(mybackground);
                                                    }
                      
                                              })
                                        
                                      
                                
                                        
                                        }
                                });
                                //AUTO TOUR CODE ENDS HERE. 

                             var tourPosition = 0
                                var manualcontrol = false;
                                var animationdelay = 0
                                localStorage.setItem('manualcontrol','false');

                                     //var tourPosition = 0
                               //var manualcontrol = false;
                               //localStorage.setItem('manualcontrol',false);
                                //var animationdelay = 0

                                function nextPositioninTour(delay){
                                  console.log('starting camera animation')
                                  document.querySelector("#camera").addEventListener('animationcomplete', zoomInBeforeMainAnimation)
                                   //alert(localStorage.getItem('manualcontrol'))
                                  //alert(gallery.value.pics.length)
                                  if (localStorage.getItem('manualcontrol') == 'true') {
                                    //alert(localStorage.getItem('manualcontrol'))
                                    manualcontrol = true;
                                    camera.setAttribute("look-controls", " ");
                                  };
                                                     //start tour
                                                     if (!manualcontrol && gallery.value.pics.length > tourPosition) {
                                                              
                                                              // var camera = document.getElementById('camera')
                                                              var stringArray = imagePositionsArray[tourPosition].split(/(\s+)/);

                                                              var easing = 'easeInSine'
                                                               //camera.removeAttribute("look-controls");

                                                              camera.setAttribute('animation', {
                                                                    property: 'position',
                                                                    dur: 2500,
                                                                    delay: delay,
                                                                    easing: easing,
                                                                    to: {x: stringArray[0]},
                                                                    startEvents: 'first'
                                                                });

                                                                camera.setAttribute('animation', {
                                                                    property: 'position',
                                                                    dur: 2500,
                                                                    delay: delay,
                                                                    easing: easing,
                                                                    to: {y: 2},
                                                                    startEvents: 'second'
                                                                });

                                                                //'33' here is how far back building1 is. TODO: make future proof load in building.z property
                                                                    
                                                                 
                                                           
                                                                //alert(stringArray[4])
                                                                camera.setAttribute('animation', {
                                                                    property: 'position',
                                                                    dur: 2500,
                                                                    delay: delay,
                                                                    easing: easing,
                                                                    to: {z: stringArray[4] - (33 - 4.5)},
                                                                    startEvents: 'third'
                                                                });

                                                                camera.setAttribute('animation__lastone', {
                                                                    property: 'rotation',
                                                                    dur: 2500,
                                                                    delay: delay,
                                                                    easing: easing,
                                                                    to: {y: 0},
                                                                    startEvents: 'fourth'
                                                                });

                                                                  // if(delay != 0){
                                                                  //   camera.emit('thirda', null, false);
                                                                  // }
                                                                camera.emit('first', null, false);
                                                                camera.emit('second', null, false);
                                                                camera.emit('third', null, false);
                                                                

                                                                // camera.setAttribute("look-controls");
                                                                if(delay==0){
                                                                  //alert('helo')
                                                                  camera.components["look-controls"].yawObject.rotation.y = 0
                                                                  camera.emit('fourth', 'testlast', false);

                                                                  
                                                                } else {
                                                                  
                                                                }

                                                              
                                                                

                                                            
                                                                document.querySelector("#camera").addEventListener('animationcomplete', zoomInBeforeMainAnimation)

                                                                //  el.addEventListener('click', imageclicked, false)

                                                                
                                                     } else {
                                                       //reached end of tour
                                                                    if(gallery.value.pics.length > 1){
                                                                        document.getElementById("snackbar3").className = "show"
                                                                      setTimeout(function(){ document.getElementById("snackbar3").className = "" }, 3000);
                                                                    }
                                                                   
                                                     }
                                                     
                                }

                                function zoomInBeforeMainAnimation(e){
                                  //alert(e.detail)
                                  console.log(e.detail.name)

                                  var checkString = String(e.detail.name)
                                  //alert(checkString)
                                  console.log(typeof checkString);

                                  if(checkString == 'animation__addimagecloseform'){
                                    //alert("wed crazy 1")
                                    console.log('animations should stop here!!')
                                    var camera = document.getElementById('camera')
                                              camera.removeAttribute("animation");
                                              camera.removeAttribute("animation__lastone");
                                              camera.removeAttribute("animation__lastonex");
                                              camera.removeAttribute("animation__x");
                                              camera.removeAttribute("animation__addimagecloseform");
                                  } else if (checkString == 'animation__addimage'){
                                            //alert("wed crazy 2")
                                              console.log('animations should stop here2!!')
                                              var camera = document.getElementById('camera')
                                              camera.removeAttribute("animation");
                                              camera.removeAttribute("animation__lastone");
                                              camera.removeAttribute("animation__lastonex");
                                              camera.removeAttribute("animation__x");
                                              camera.removeAttribute("animation__addimagecloseform");
                                  } else {
                                           console.log('animation should not start if just adding images')
                                          var stringArray = imagePositionsArray[tourPosition].split(/(\s+)/);
                                  //alert(stringArray[4])
                                              var camera = document.getElementById('camera')
                                              camera.removeAttribute("animation");
                                              camera.removeAttribute("animation__lastone");
                                              camera.removeAttribute("animation__lastonex");

                                               camera.setAttribute('animation__x', {
                                                                            property: 'position',
                                                                            dur: 6000,
                                                                            delay: 0,
                                                                            easing: 'easeInSine',
                                                                            to: {z: stringArray[4] - (33 - 2.5)},
                                                                            startEvents: 'thirda'
                                                }); 
                                                camera.emit('thirda', null, false);

                                                document.querySelector("#camera").addEventListener('animationcomplete__x', lateFri)
                                               
                                                //alert('helo')
                                  }

                                 
                               
                                  
                                  

                                }


                                function lateFri(e){
                                   console.log(e.detail)
                                                                  if(e.detail.name == 'animation__x'){
                                                                    console.log('tourPosition ' + tourPosition)
                                                                    // console.log(e.detail.name)
                                                                    // console.log('animation complete')
                                                                      // camera.setAttribute("look-controls", " ");
                                                                      // camera.components["look-controls"].yawObject.rotation.y = 0

                                                                    
                                                                    camera.removeAttribute("animation");
                                                                    camera.removeAttribute("animation__x");

                                                             
                                                                       

                                                                    //alert(tourPosition)
                                                                    //document.querySelector("#camera").removeEventListener("animationcomplete__lastone", function(){});
                                                                    tourPosition++
                                                                      if (localStorage.getItem('manualcontrol') == 'true') {
                                                                        //alert(localStorage.getItem('manualcontrol'))
                                                                        manualcontrol = true;
                                                                      };

                                                                    if(!manualcontrol){
                                                                      nextPositioninTour(1000)

                                                                          // const myTimeout = setTimeout(
                                                                          //   turnofflookcontrols, 3000);

                                                                          // const myTimeout1 = setTimeout(
                                                                          //   turnonlookcontrols, 5500);


                                                                    }
                                                                    
                                                                    
                                                                  } else {
                                                                    console.log('name didnt match')
                                                                    tourPosition--
                                                                  }
                                }

                                function turnonlookcontrols() {
                                 //alert('turnonlookcontrols')
                                  camera.setAttribute("look-controls", " ")
                                   //camera.components["look-controls"].yawObject.rotation.y = 0
                                }

                                function turnofflookcontrols() {
                                  //alert('turnofflookcontrols')
                                  camera.removeAttribute("look-controls")
                                }

                                //START OF DONEXT HERE. CODE THAT RUNS WHEN AN IMAGE IS TAPPED
                                
                                delete AFRAME.components['donext']
                                                          AFRAME.registerComponent('donext', {
                                                                  init: function(){
                                                                      var el = this.el;
                                                                      var data = this.data;
                                                                      // var why1 = document.querySelector("#why1");
                                                                      //var myScene = document.querySelector('a-scene');
                                                                            //var camera = document.getElementById('camera');
                                                                            //console.log(entityEl.object3DMap);
                                                                            el.addEventListener('click', imageclicked, false)

                                                                            if(vrheadset){
                                                                                el.addEventListener('raycaster-intersected', imageclicked, false)
                                                                            }
                                                                            

                                                                    
                                                                            
                                                                            // if(fullvr){
                                                                            //    el.addEventListener('raycaster-intersected', imageclicked, false) 
                                                                            // }
                                                                            
                                                                            //el.addEventListener('raycaster-intersected', imageraycaster, false)

                                                                            //   this.el.addEventListener("raycaster-intersected'", (e)=> {
                                                                    //     //  window.location = this.data.href;
                                                                    //      window.open(this.data.href);
                                                                    //   })

                                                                            // function imageraycaster(evt){
                                                                            //     //alert('raycaster')
                                                                            //     if(evt.detail.el.id=="bulletraycaster"){
                                                                            //         //alert('is this triggered??')
                                                                            //         positionmove()
                                                                            //     }
                                                                            // }

                                                                            function imageclicked(evt){
                                                                                manualcontrol = true;
                                                                                cam = document.getElementById("camera");
                                                                                  cam.removeAttribute("animation");
                                                                                  cam.removeAttribute("animation__lastone");
                                                                                  cam.removeAttribute("animation__x");
                                                                                var tapimageinfo = document.querySelector("#tapimageinfo");
                                                                                tapimageinfo.setAttribute("visible",false);  
                                                                                positionmove()
                                                                                  
                                                                            }

                                                                            function positionmove(){
                                                                                const mesh = el.object3D; 
                                                                                const worldpos = new THREE.Vector3();
                                                                                var actualPosition = mesh.getWorldPosition(worldpos)
                                                                                console.log(actualPosition)

                                                                                const thisRotation = el.parentNode.getAttribute('rotation')
                                                                                console.log(thisRotation.y)
                                                                                var camera = document.getElementById('camera')
                                                                                var player = document.getElementById('player')

                                                                                if (el.classList.contains('building1')){
                                                                                    if (thisRotation.y > 0 ){
                                                                                        // positioned at an angle
                                                                                        if (thisRotation.y == 90 ){
                                                                                            camera.setAttribute('position', {x: actualPosition.x + 4.5, y: 2, z: actualPosition.z}); 
                                                                                            camera.components["look-controls"].yawObject.rotation.y = 1.57
                                                                                        }
                                                                    
                                                                                        if (thisRotation.y == 270 ){
                                                                                            camera.setAttribute('position', {x: actualPosition.x - 4.5, y: actualPosition.y, z: actualPosition.z}); 
                                                                                            camera.components["look-controls"].yawObject.rotation.y = -1.57
                                                                                        }
                                                                                    } else{
                                                                                        //we know if's not at an angle, so look straight on
                                                                                        //console.log('hello11')
                                                                                        camera.setAttribute('position', {x: actualPosition.x, y: 2, z: actualPosition.z + 4.5}); 
                                                                                        camera.components["look-controls"].yawObject.rotation.y = 0
                                                                    
                                                                                    }
                                                                                } 

                                                                                if (el.classList.contains('building2')){
                                                                                    
                                                                                    if (thisRotation.y > 0 ){
                                                                                        // positioned at an angle
                                                                                        if (thisRotation.y == 90 ){
                                                                                            
                                                                                            camera.setAttribute('position', {x: actualPosition.x + 4.5, y: 2, z: actualPosition.z}); 
                                                                                            camera.components["look-controls"].yawObject.rotation.y = 1.57
                                                                                        }
                                                                    
                                                                                        if (thisRotation.y == 270 ){
                                                                                            camera.setAttribute('position', {x: actualPosition.x - 4.5, y: actualPosition.y, z: actualPosition.z}); 
                                                                                            camera.components["look-controls"].yawObject.rotation.y = -1.57
                                                                                        }
                                                                                    } else{
                                                                                        //we know if's not at an angle, so look straight on
                                                                                        camera.setAttribute('position', {x: actualPosition.x - 4.5, y: 2, z: actualPosition.z}); 
                                                                                        // camera.components["look-controls"].yawObject.rotation.y = 0
                                                                                        camera.components["look-controls"].yawObject.rotation.y = -1.57
                                                                                        camera.components["look-controls"].pitchObject.rotation.x = -0.1
                                                                                        //document.querySelector("#camera").components["look-controls"].pitchObject.rotation.x 
                                                                                    }
                                                                                } 

                                                                                if (el.classList.contains('building3')){
                                                                                    if (thisRotation.y > 0 ){
                                                                                        // positioned at an angle
                                                                                        if (thisRotation.y == 90 ){
                                                                                            
                                                                                            camera.setAttribute('position', {x: actualPosition.x, y: 2, z: actualPosition.z - 4.5}); 
                                                                                            camera.components["look-controls"].yawObject.rotation.y = 1.57
                                                                                        }
                                                                    
                                                                                        if (thisRotation.y == 270 ){
                                                                                            camera.setAttribute('position', {x: actualPosition.x, y: actualPosition.y, z: actualPosition.z - 4.5}); 
                                                                                            camera.components["look-controls"].yawObject.rotation.y = -1.57
                                                                                        }
                                                                                    } else{
                                                                                        //we know if's not at an angle, so look straight on
                                                                                        camera.setAttribute('position', {x: actualPosition.x, y: 2, z: actualPosition.z -4.5}); 
                                                                                        // camera.components["look-controls"].yawObject.rotation.y = 0
                                                                                        // camera.components["look-controls"].yawObject.rotation.y = -1.57
                                                                                        camera.components["look-controls"].pitchObject.rotation.x = 0
                                                                                        camera.components["look-controls"].yawObject.rotation.y = 179.11
                                                                                        // camera.components["look-controls"].yawObject.rotation.y = 2.9
                                                                                        //document.querySelector("#camera").components["look-controls"].pitchObject.rotation.x 
                                                                                    }
                                                                                } 

                                                                                if (el.classList.contains('building4')){
                                                                                  
                                                                                } 
                                                                            }

                                                                    
                                                                    }
                                                                });


                                //END OF DONEXT



                                //start of cameraanimation

                           
                       

            delete AFRAME.components['mylaser']                     
        AFRAME.registerComponent("mylaser", {
          init: function() {
            this.el.addEventListener('raycaster-intersection', (evt) => {
              AFRAME.log("laser hit something")
              //let tmp = evt.detail.getIntersection(this.el);      
              //AFRAME.log(evt.target);
              AFRAME.log(evt);
              // evt.detail.getIntersection(this.el);
            })
            }
          })
     
          


        return {error, gallery, featuredgallery, featuredgalleryerror, ownership, handleDelete, handlePicDelete, handleGalleryEdit, editMode, AddImage, checkVars, imagePosition, imagePositionsArray, imagePositionsArrayVR, imageRotationArray, imagePositionsArray1, imageRotationArray1, imageScaleDivider1, galleryURL, vrheadset, vrmode, pinprotected, handlePinCheck, pin, pinProvided, unlocked, hidePinPad, instant, moody, ornate, retail, neon, halloween, summer, birthday, wedding,scenefog, desktopNotOwner, desktopOwner, headsetOwner, headsetNotOwner, mobileOwner, mobileNotOwner, startingSong, neonStartingSong, birthdayStartingSong, classicalStartingSong, user, showFaves, showFavesButton, showArtist, showArtistButton, people, favedpic, taporclick, incrementHomePageView}
        

        
    },
    methods: {
    }
    
}

  
</script>

<style>

/* .mainContainer {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  height: 95vh;
  width: 200vw;


} */

.noscroll{
  margin: 0; height: 100%; overflow: hidden; 
}

.acontainer{
     height: 94vh;
     padding: 0 !important;
     overflow: hidden !important;
     

}




.banner{
    color:c9011a;
}

.cover{
    overflow: hidden;
    border-radius: 20px;
    position: relative;
    padding: 160px;
}

.cover img{

    display: block;
    position:absolute;
    top:0;
    left:0;
    min-width: 100%;
    min-height: 100%;
    max-width: 200%;
    max-height: 200%;
}

.gallery-info{
    text-align: center;
}

.gallery-info h2 {
    text-transform: capitalize;
    font-size: 28px;
    margin-top: 20px;
}

.gallery-info p {
    margin-bottom: 20px;
}

.username{
    color: #999;
}

.description {
    text-align: left;
}

.single-image{
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: center;
    border-bottom: 1px dashed var(--secondary);
    margin-bottom: 20px;

}
/* 
.aframeDiv{
    grid-area: header; 
    
}
a-scene {
  height: 100%;
  width: 100%;
} */

a-scene {
    /* position: fixed; */
    position:fixed;
  height: 95%;
   width: 100%;
}

/** Social Button CSS **/


.share-btn {
  display: inline-block;
  color: #ffffff;
  border: none;
  padding: 0.1em 0.6em;
  outline: none;
  text-align: center;
  font-size: 0.9em;
  margin: 0 0.2em;
}

.share-btn:focus,
.share-btn:hover {
  text-decoration: none;
  opacity: 0.8;
}

.share-btn:active {
  color: #e2e2e2;
}

.share-btn.twitter     { background: #55acee; }
.share-btn.google-plus { background: #dd4b39; }
.share-btn.facebook    { background: #3B5998; }
.share-btn.stumbleupon { background: #EB4823; }
.share-btn.reddit      { background: #ff5700; }
.share-btn.hackernews  { background: #ff6600; }
.share-btn.linkedin    { background: #4875B4; }
.share-btn.email       { background: #444444; }

/* #np{
    opacity: 0.1 !important;
} */

.navbuttons{
    opacity:0.5;
    display:block; 
    width:20px; 
    height:20px;
    background-color:#393c41; 
    z-index:20;
    border-radius: 50%;
     right:5%; 
    bottom:5%;
}

/* .editGallery{
  position:fixed;
  width:20px; 
    height:20px;
       left:1%; 
    bottom:1%;
    
    
} */

.halfOpacity{
  opacity: 0.5;
}

.fullOpacity{
  opacity: 1;
}

#backButton{ 
    position:absolute;     
    right:10%; 
    bottom:10%;
  
}

#shareButton{
    position:absolute;  
     right:30%; 
    bottom:10%;
}

.sharing{
  position: fixed;
  /* background: orange; */
  /* width: 100px;
  height: 100px; */
  text-shadow: 1px 1px 0px #000; /* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
  right: 5px;
  top:5px;
}

/* .a-enter-vr-button{
  bottom:0% !important;

} */

.a-enter-vr-button{
  bottom:50px !important;
  right:15px !important;
  position:fixed;

}

 .a-loader-title {
   color: black;
 }

 .swal-button {
  padding: 7px 19px;
  border-radius: 2px;
  background-color: #da0f41;
  font-size: 12px;
 
  border: 1px solid #da0f41;
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);
}

.swal2-popup, .swal-title{
  
   font-family: 'Nunito', sans-serif !important;
}

#deleteGalleryButton{
   position:absolute;  
  margin: auto;
  width: 35%;
  border: 1px solid white;
  padding: 0px;
  z-index: 2001;
  bottom:-1%;
  left:25%;
}

#entervrimage{
  bottom:100px; right: 1%; z-index:5000; position: fixed; height: 80px; width: 80px;
  opacity:0.8;
   border: 1px solid #fff;
  padding: 1px;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

#editGalleryButton{
  
   bottom:1%; right: 100px; z-index:500; position: fixed; height: 80px; width: 80px;
  opacity:0.75;
   border: 2px solid #fff;
  padding: 1px;
  border-radius: 5%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 600px) {
  #editGalleryButton{
      bottom:1%; right: 80px; z-index:500; position: fixed; height: 60px; width: 60px;
      opacity:0.88;
      border: 1px solid #fff;
      padding: 1px;
      border-radius: 50%;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  #entervrimage{
      bottom:100px; right: 1%; z-index:5000; position: fixed; height: 60px; width: 60px;
      opacity:0.8;
      border: 1px solid #fff;
      padding: 1px;
      border-radius: 50%;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
  
}

#entervrimage:hover,  #editGalleryButton:hover{
  -o-transition:.25s;
  -ms-transition:.25s;
  -moz-transition:.25s;
  -webkit-transition:.25s;
  transition:.25s;
  box-shadow: 0 0 3px 3px #fff;
}
    

#snackbar3, #snackbar4 {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: #da0f41; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 2999 !important; /* Add a z-index if needed */
  left: 45%; /* Center the snackbar */
  bottom: 200px; /* 30px from the bottom */
  font-family: 'Nunito', sans-serif !important;
    border-style: solid;
  border-color: white;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar3.show, #snackbar4.show {
  visibility: visible; /* Show the snackbar */
   z-index: 2999 !important; /* Add a z-index if needed */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 200px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 200px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 200px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 200px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}



</style>