<template>
  <div class="home container" >
    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- <router-link :to=" {name: 'CreateGallery'} " class="btn bg-white">Create a new Gallery</router-link> -->
    
  
    
      <!-- The galleries you create can be shared easily - no account needed to view your collections. -->
          <!-- <div class="ui icon input" style="width: 100%">
              <input type="text" placeholder="Search..." v-model="searchQuery" />
              <i class="search icon"></i>
          </div> -->

          <!-- <div
              class="card ui fluid"
              v-for="product in searchedProducts"
              :key="product.id"
              style="margin: 0"
            >
              ...
            </div> -->


     <br><br><h2>New Galleries</h2>
    <div v-if="error" class="error">Could not fetch the data</div>
    <div v-if="newgalleries">
       <ListView :galleries="newgalleries" />
          <!-- <router-link :to="{ name: 'Home' }" >
          <button class=" btn btn-sm floatingbutton responsiveText" >Show More <b>New</b> Galleries</button>
        </router-link> -->
      
    </div>

     <!-- <br><br><h2>Popular Creators</h2>
     <div v-if="documents">
      <PeopleView :people="documents"/>
    </div> -->


  </div>
</template>

<script>
import getUser from '@/composables/getUser'

import ListView from '../components/ListView.vue'
import getCollection from '../composables/getCollection'
import AddImage from '../components/AddImage.vue'

// import { ref } from 'vue'
// import { computed } from '@vue/reactivity'


export default {
  name: 'New',
  components: {ListView, AddImage},
  setup() {
    // const { error, documents } = getCollection('galleries')
      const { user } = getUser()

  
     


         const { documents: newgalleries } = getCollection(
            'galleries',
            ['sharingOptions', '==', 'discoverable'],
            'new50'
        )
    // console.log('home')
    // console.log(documents)

    return {newgalleries, user}
  }
}
</script>

<style >
  
</style>
