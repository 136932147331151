<template>
  <div class="home container" >
    <!-- <img alt="Vue logo" src="../assets/logo.png">
    <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- <router-link :to=" {name: 'CreateGallery'} " class="btn bg-white">Create a new Gallery</router-link> -->
    
 
    
      <!-- The galleries you create can be shared easily - no account needed to view your collections. -->
          <!-- <div class="ui icon input" style="width: 100%">
              <input type="text" placeholder="Search..." v-model="searchQuery" />
              <i class="search icon"></i>
          </div> -->

          <!-- <div
              class="card ui fluid"
              v-for="product in searchedProducts"
              :key="product.id"
              style="margin: 0"
            >
              ...
            </div> -->

    
    
   <br><br><h2>Popular Galleries</h2>
    <div v-if="error" class="error">Could not fetch the data</div>
    <div v-if="galleries">
       <ListView :galleries="galleries" />
           <!-- <router-link :to="{ name: 'Home' }" >
          <button class=" btn btn-sm floatingbutton responsiveText" >Show More <strong>Popular</strong> Galleries</button>
        </router-link> -->

      
    </div>

       
      
         <!-- <div v-if="!user">
            <p>Signup instantly for a free acount and get creating!!</p>
        </div>
          <div v-if="user && !usergalleries">
            <p>User found, but no galleries</p>
        </div> -->
        

 

     <!-- <br><br><h2>Popular Creators</h2>
     <div v-if="documents">
      <PeopleView :people="documents"/>
    </div> -->


  </div>
</template>

<script>
import getUser from '@/composables/getUser'

import ListView from '../components/ListView.vue'
import getCollection from '../composables/getCollection'
import AddImage from '../components/AddImage.vue'

// import { ref } from 'vue'
// import { computed } from '@vue/reactivity'


export default {
  name: 'Popular',
  components: {ListView, AddImage},
  setup() {
    // const { error, documents } = getCollection('galleries')
      const { user } = getUser()

 
            //console.log('test user')
            //console.log(user.value.uid)


      const { documents: galleries } = getCollection(
            'galleries',
            ['sharingOptions', '==', 'discoverable'],
            'popular50'
        )


      // const searchQuery = ref("");

      // const searchedProducts = computed(() => {
      //     return galleries.value.filter((product) => {
      //       return (
      //         product.title
      //           .toLowerCase()
      //           .indexOf(searchQuery.value.toLowerCase()) != -1
      //       );
      //     });
      // });
     


    // console.log('home')
    // console.log(documents)

    return {galleries, user}
  }
}
</script>

<style >


  
</style>
