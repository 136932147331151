<template>
  <div class="add-image">
      <!-- <button class="addImageButton" v-if='!showForm' @click='showForm = true'>Add Image</button> -->
      <div id="addImageButton" 
      v-if='!showForm' @click='addImageButton'>   
        <img id="addImageButton" src="/images/addimage.png"  >            
                <!-- <i style="color:white;" class="fas fa-plus-circle fa-2x"></i><span style="color:white; ">image</span>    -->
                <!-- <i class="material-icons" style="font-size:33px;color:#c9011a;text-shadow:2px 2px 4px #000000;">add_circle</i> -->
        </div>

        <div v-if="checkUserIsPremium"></div>
         
        
      <form v-if='showForm && !maxLimitReached' @submit.prevent="handleSubmit" id="customform" >
          <!-- class="dropzone" -->
          <h4>Add new image</h4>
          <!-- <input type="file" @change="handleChange" :key="fileInputKey" required    > -->

        <input type="file" @change="handleChange" :key="fileInputKey" required id="imageDrop"    >

         <div v-if="imageLoaded">
            <img :src="resizedImg" height="200" style=" display: block; margin-left: auto; margin-right: auto;" />
          </div>

         <input type="text" placeholder="Image Title" v-model='title' maxlength="40">
          <textarea type="text" placeholder="Description (optional)"  v-model='description' maxlength="100"></textarea>
            <input type="url" placeholder="Link (optional)" v-model='picurl' >
            
            <button v-if="!isPending" class="btn btn-primary"  style="padding:5px !important;">Add To Gallery</button> 
          <button v-if="isPending" disabled>Saving...</button> 

          
          <!-- <input type="file" @change="handleChange"> TODO hook this up later-->
           

            <div class="error">{{ fileError }}</div>
          <!-- <button>Add</button> -->
          
          <!-- //TODO buttonfor the close button, we'll send the camera to the most recent image added -->
          <button style="position: absolute; top:2%; right:5%" type="button" class="btn-close" @click='closeForm' aria-label="Close"></button>
      </form>


      <form v-if='show15LimitForm' @submit.prevent="createSubscription" >
        <h4>Image Limit Reached!!!</h4>
        <p>You have reached the 15 image limit for this gallery. Either upgrade to a premium account which allows you to add 30 images per gallery. Or simply a create a new gallery and add your images there (premium account not needed). </p>
          <button v-if="!isPending1" class="btn btn-sm center " @click="createSubscription">Upgrade Now!</button>
          <button v-if="isPending1" class="btn btn-sm center" @click="createSubscription">Loading payment options...</button>
          <button class="btn" @click="closeForm">Cancel</button> 
      </form>

        <form v-if='show30LimitForm' @submit.prevent="" >
        <h4>Image Limit Reached!!!</h4>
        <p>You have reached the 30 image limit for this premium gallery. Create a new gallery and add your images there. </p>
          <!-- <button class="btn btn-primary">Upgrade to Premium</button>  -->
          <button class="btn" @click="closeForm">OK</button> 
      </form>

      
        <div id="snackbar1">Image being added to gallery..</div>
        <div id="snackbar2">IMAGE ADDED to gallery!!</div>
  </div>
  
</template>

<script>
import {ref} from 'vue'
import useDocument from '@/composables/useDocument'
// import { projectStorage } from '../firebase/config'
import useStorage from '@/composables/useStorage'
// import { timestamp } from '@/firebase/config'
// import Vue from 'vue';
import getUser from '@/composables/getUser'
import { computed } from '@vue/reactivity'

import { projectFirestore } from '@/firebase/config'

//NOTE, remember for images that resize on the server - like the thumbnails - if the extension is modifed or updated, have to comment out the lines of code that create a new access token.
//To do this, go to the google cloud console > gallery > cloud functions > extensions > source, then 'source code', and comment out the 3 lines (starting with.... )
// https://stackoverflow.com/questions/68895327/avoid-re-generating-token-when-using-firebase-image-resize-extension
// From here browse to the lib/resize-image.js file and remove lines 110..112 or add a comment for the whole line 111

export default {
    props:['gallery'],
    // components: { UploadImage },
    setup(props) {
        // console.log(props)
        const title = ref('')
        const description = ref('')
        const picurl = ref('')
        const fileInputKey = ref('')
        const showForm = ref(false)
        const show15LimitForm = ref(false)
        const show30LimitForm = ref(false)
        const maxLimitReached = ref(false)
        const premiumMaxLimitReached = ref(false)
        const { updateDoc } = useDocument('galleries', props.gallery.id)
        const { filePath, url, uploadImage } = useStorage()
        const file = ref(null)
        const fileError = ref(null)
        const isPending = ref(false)
        const isPending1 = ref(false)
        const imageLoaded = ref(false)
        const resizedImg = ref('')

       
        var dataurl = ""
        
        var newimagefile = ""

        const { user } = getUser()
   //var showForm = false

               const isPremium = ref(false)
              const premiumQueryReturned = ref(false)


            const checkUserIsPremium = computed (() => {
              //alert('checking')
                 projectFirestore.collection('people')
                            .doc(user.value.uid)
                            .collection('subscriptions')
                            .where('status', 'in', ['trialing', 'active'])
                            .onSnapshot(async (snapshot) => {
                                // In this implementation we only expect one active or trialing subscription to exist.
                                const doc = snapshot.docs[0];
                                //console.log(doc.id, ' => ', doc.data());
                                if(doc){
                                    console.log("premium exisists!")
                                    premiumQueryReturned.value = true 
                                    isPremium.value = true;
                                    return true
                                } else {
                                        console.log("premium  doesnay exisists!")
                                        premiumQueryReturned.value = true 
                                        isPremium.value = false;
                                        return false
                                }
                           });
            })

            


              const createSubscription = async (doc) => {
                        isPending1.value = true

                                    const docRef = await projectFirestore
                                        .collection('people')
                                        .doc(user.value.uid)
                                        .collection('checkout_sessions')
                                        .add({
                                            price: 'price_1LFOt2HEkUUNQu3qhNPu4gy6',
                                            success_url: window.location.origin,
                                            cancel_url: window.location.origin,
                                        });
                                        // Wait for the CheckoutSession to get attached by the extension
                                        docRef.onSnapshot((snap) => {
                                        const { error, url } = snap.data();
                                        if (error) {
                                            // Show an error to your customer and
                                            // inspect your Cloud Function logs in the Firebase console.
                                            alert(`An error occured: ${error.message}`);
                                        }
                                        if (url) {
                                            // We have a Stripe Checkout URL, let's redirect.
                                            window.location.assign(url);
                                        }
                                        });
                    }

   const closeForm = async () => {


          showForm.value = false
          show15LimitForm.value = false
          show30LimitForm.value = false
          // maxLimitReached.value=false

                  var camera = document.getElementById('camera')
                  const staticArrayPosition = props.gallery.pics.length-1

                    var stringArray = globalImagePositionsArray[staticArrayPosition].split(/(\s+)/);
                    //camera.setAttribute('position', {x: 0+ 4.5, y: 0 + 2, z: 0 + actualPosition.z}); 
                    //camera.components["look-controls"].yawObject.rotation.y = 1.57
                    console.log(stringArray[0])
                    console.log(stringArray[2])
                    console.log(stringArray[4])
                                 	camera.setAttribute('animation', {
                                    property: 'position',
                                    dur: 2500,
                                    easing:'easeInSine',
                                    to: {x: stringArray[0]}
                                });

                                camera.setAttribute('animation', {
                                    property: 'position',
                                    dur: 2500,
                                    easing:'easeInSine',
                                    to: {y: 2}
                                });

                                camera.setAttribute('animation', {
                                    property: 'position',
                                    dur: 2500,
                                    easing:'easeInSine',
                                        to: {z: stringArray[4] - (33 - 4.5)},
                                });

                                 camera.setAttribute('animation__addimagecloseform', {
                                    property: 'rotation',
                                    dur: 2500,
                                    easing:'easeInSine',
                                    to: {y: 0}
                                });

                        camera.components["look-controls"].yawObject.rotation.y = 0
                        //alert('should be add of animation')


   }
  
      const addImageButton = async () => {
            //check # of images, if 15 or less allow to add (or 30 for premium)
          //if 15 or greater prompt for premium

                  //alert(props.gallery)
                  console.log(props.gallery.pics.length)

                  document.querySelector("#camera").object3D.position.set(-20, 10, -5);
                   //camera.components["look-controls"].yawObject.rotation.y = -0.7

                                          var camera = document.getElementById('camera')
                                              camera.removeAttribute("animation");
                                              camera.removeAttribute("animation__lastone");
                                              camera.removeAttribute("animation__lastonex");
                                              camera.removeAttribute("animation__x");
                                              camera.removeAttribute("animation__addimagecloseform");

                  camera.components["look-controls"].yawObject.rotation.y = -0.7
                  //console.log(globalImagePositionsArray[props.gallery.pics.length])
                  //alert(globalImagePositionsArray[props.gallery.pics.length])
                  //IF STATEMENT PREMIUM USER

                  // if(props.gallery.pics.length> 14){

                    //  premiumQueryReturned.value = true 
                    // isPremium.value = false;
                    //maxLimitReached.value = true
                    //show15LimitForm.value = true
                    //showForm.value = false
                    
                    if(props.gallery.pics.length> 29 && isPremium.value == true){
                      //alert("premium user, reached limit")
                      show30LimitForm.value = true
                      premiumMaxLimitReached.value = true

                  
                  } else if (props.gallery.pics.length> 14 && isPremium.value == false) {
                    //alert("standard user, reached limit")
                    //show30LimitForm.value = true
                     maxLimitReached.value = true
                     show15LimitForm.value = true
                  } else {
                    showForm.value = true
                  }

          //else

          
        }

        const handleSubmit = async () => {

            if(file.value){
                isPending.value = true
                // await uploadImage(file.value)
                console.log('start of handlesubmit here')
                // console.log(file.value)
                // console.log(dataurl)
                // console.log("new image file dagta here:")
                // console.log(newimagefile)

                //alert(newimagefile)
                document.getElementById("snackbar1").className = "show"
                await uploadImage(newimagefile)
                document.getElementById("snackbar1").className = ""
                document.getElementById("snackbar2").className = "show"
                setTimeout(function(){ document.getElementById("snackbar2").className = "" }, 3000);


                const y = url.value.lastIndexOf(".")
                const thumbnailurl = url.value.substring(0,y) + "_200x200" + url.value.substring(y); 
                // const resizedurl = url.value.substring(0,y) + "_2000x2000" + url.value.substring(y); 
                const resizedurl = url.value;
                console.log(resizedurl)

                const srcWidth = image.width;
                const srcHeight = image.height;
                const maxWidth = 4032
                const maxHeight = 4032
                //TODO move to gallery details!
                var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
                const scaledWidth = srcWidth*ratio;
                const scaledHeight = srcHeight*ratio;

                // async function f1() {
                //   let storageRef = await projectStorage.refFromURL(resizedurl)
                // }

                if (title.value == ""){
                  title.value = "Instant.Gallery image by:\n" + user.value.displayName

                }

                const newImage = ({
                    title: title.value,
                    description: description.value,                  
                    coverUrl: url.value,
                    thumbnailUrl: thumbnailurl,
                    resizedUrl: resizedurl,
                    filePath: filePath.value,  
                    //filePath: resizedFileName,  
                    originalOriginalWidth:  image.width,
                    originalOriginalHeight: image.height, 
                    originalWidth: scaledWidth,
                    originalHeight: scaledHeight,
                    resized:0,
                    faves:0,
                    picurl:picurl.value,
                    ownerid:user.value.uid,
                    ownerDisplayName:user.value.displayName,
                    id: Math.floor(Math.random() * 10000000),

                    //createdAt: timestamp()
                })
                // isPending.value = false

                // const newImage = {
                //     title: title.value,
                //     description: description.value,
                //     id: Math.floor(Math.random() * 1000000),
                //     coverUrl: url.value,
                //     filePath: filePath.value,
                //     // TODO update the random id
                // }
            // Pete: was working well, maybe add back in
            title.value = ''
            description.value = ''
            picurl.value = ''
            fileInputKey.value = '0'
            file.value = 'Upload a file'
            document.getElementById('customform').reset();
            // TODO maybe provide messgae:  Image added to gallery!!, go and see it
                //var x = document.getElementById("snackbar");
                // Add the "show" class to DIV
                //x.className = "show";
                // After 3 seconds, remove the show class from DIV

                const staticArrayPosition = props.gallery.pics.length

                   await updateDoc({
                                                pics:[...props.gallery.pics, newImage]
                    })
                    clearAllInputs();
                    isPending.value = false;
                    imageLoaded.value = false;

                    //here we want to move camera to position
                    //document.querySelector("#camera").object3D.position.set(1, 10, 1);


                    var camera = document.getElementById('camera')

                    var stringArray = globalImagePositionsArray[staticArrayPosition].split(/(\s+)/);
                    //camera.setAttribute('position', {x: 0+ 4.5, y: 0 + 2, z: 0 + actualPosition.z}); 
                    //camera.components["look-controls"].yawObject.rotation.y = 1.57
                    console.log(stringArray[0])
                    console.log(stringArray[2])
                    console.log(stringArray[4])

                    // document.querySelector("#camera").removeEventListener('animationcomplete', zoomInBeforeMainAnimation)
                    // camera.removeAttribute("animation");
                    // camera.removeAttribute("animation__1");
                    // camera.removeAttribute("animation__lastone");
                    // camera.removeAttribute("animation__lastonex");
                    // camera.removeAttribute("animation__x");
                    // var old_element = document.getElementById("camera");
                    // var new_element = old_element.cloneNode(true);
                    // old_element.parentNode.replaceChild(new_element, old_element);
                                              

                  //            	camera.setAttribute('animation', {
                  //                   property: 'position',
                  //                   dur: 2500,
                  //                   easing:'easeInSine',
                  //                   to: {x: stringArray[0]}
                  //               });

                  //               camera.setAttribute('animation', {
                  //                   property: 'position',
                  //                   dur: 2500,
                  //                   easing:'easeInSine',
                  //                   to: {y: 2}
                  //               });

                  //               camera.setAttribute('animation', {
                  //                   property: 'position',
                  //                   dur: 2500,
                  //                   easing:'easeInSine',
                  //                       to: {z: stringArray[4] - (33 - 4.5)},
                  //               });

                  //                camera.setAttribute('animation__addimage', {
                  //                   property: 'rotation',
                  //                   dur: 2500,
                  //                   easing:'easeInSine',
                  //                   to: {y: 0}
                  //               });

                  //       camera.components["look-controls"].yawObject.rotation.y = 0

                  // var camera = document.getElementById('camera')
                  //                             camera.removeAttribute("animation");
                  //                             camera.removeAttribute("animation__lastone");
                  //                             camera.removeAttribute("animation__lastonex");
                  //                             camera.removeAttribute("animation__x");
                  //                             camera.removeAttribute("animation__addimagecloseform");


                                

                                // 	camera.setAttribute('animation', {
                                //     property: 'position.y',
                                //     dur: 2500,
                                //     easing:'easeInSine',
                                //     to: 2
                                // });

                                //     	camera.setAttribute('animation', {
                                //     property: 'position.z',
                                //     dur: 2500,
                                //     easing:'easeInSine',
                                //     to: stringArray[4]
                                // });
                                // 	camera.setAttribute('animation', {
                                //     property: 'position',
                                //     dur: 2500,
                                //     easing:'easeInSine',
                                //     to: stringArray[0]
                                // });
                                // 	camera.setAttribute('animation', {
                                //     property: 'position',
                                //     dur: 2500,
                                //     easing:'easeInSine',
                                //     to: stringArray[0]
                                // });

                                //camera.components["look-controls"].yawObject.rotation.y = 0

                        //           camera.setAttribute('position', {x: actualPosition.x, y: 2, z: actualPosition.z + 4.5}); 
                        // camera.components["look-controls"].yawObject.rotation.y = 0

                    // el.setAttribute(animation__001, {'property': position,
                    //              'to': {x: 1, y: 1: z: 1},                                 
                    //              'startEvents': 'startanim001');

                    // animation="property: position; to: 1 8 -10; dur: 2000; easing: linear; loop: true"

            
            
            }
        }

        function clearAllInputs(){
            //alert('clear all inputs')
            isPending.value = false
            title.value = ''
            description.value = ''
            picurl.value = ''
            fileInputKey.value = '0'
            file.value = 'Upload a file'
            //alert('clear all inputs end')
            ///I could try and run through the array again here. ie have timetoload boolean set to false, then set it to true
        }

         //allowed file types
        const types = ['image/png', 'image/jpeg', 'image/jpg', 'image/webp']
        const image = { size:'',
                        height:'',
                        width:''
                        }
        imageLoaded.value = false

        let tempname = ""

        const handleChange = (e) => {
            const selected = e.target.files[0]
            console.log(selected.name)
            tempname = selected.name;

                if(props.gallery.pics.length> 29 && isPremium.value == true){
                      //alert("premium user, reached limit")
                      show30LimitForm.value = true
                      premiumMaxLimitReached.value = true

                  
                  } else if (props.gallery.pics.length> 14 && isPremium.value == false) {
                    //alert("standard user, reached limit")
                    //show30LimitForm.value = true
                     maxLimitReached.value = true
                     show15LimitForm.value = true
                  } else {
                    showForm.value = true
                  }

            // document.querySelector("#camera").object3D.position.set(-12.5, 10, 1);
            // document.querySelector("#camera").components["look-controls"].pitchObject.rotation.x = document.querySelector("#camera").components["look-controls"].pitchObject.rotation.x+0.1;
            // document.querySelector("#camera").components["look-controls"].yawObject.rotation.y = document.querySelector("#camera").components["look-controls"].yawObject.rotation.y-0.2;

            //camera.components["look-controls"].pitchObject.rotation.x;
                // var frame = document.getElementById("frame");
                // frame.src=URL.createObjectURL(e.target.files[0]);
                // document.getElementById("frame").setAttribute('src', 'URL.createObjectURL(e.target.files[0])');


                        

            if(selected && types.includes(selected.type)){
                file.value = selected
                fileError.value = null  
                
                // code here to get dimensions, so can size properly in aframe
                        //then also resize before submit is clicked
                        let reader = new FileReader();
                
                        reader.readAsDataURL(selected);
                        reader.onload = evt => {
                            let img = new Image();
                            img.onload = () => {
                                image.width = img.width;
                                image.height = img.height;
                                imageLoaded.value = true  
                                resizedImg.value = evt.target.result;
                                //console.log(image.width)
                                //console.log(image.height)   
                                
                                                                                               //alert('try to resize image here')
                                                            var canvas = document.createElement('canvas');

                                                            var ctx = canvas.getContext("2d");
                                                            ctx.drawImage(img, 0, 0);

                                                            var MAX_WIDTH = 2000;
                                                            var MAX_HEIGHT = 2000;
                                                            var width = img.width;
                                                            var height = img.height;

                                                            if (width > height) {
                                                                if (width > MAX_WIDTH) {
                                                                    height *= MAX_WIDTH / width;
                                                                    width = MAX_WIDTH;
                                                            }
                                                            } else {
                                                                if (height > MAX_HEIGHT) {
                                                                    width *= MAX_HEIGHT / height;
                                                                    height = MAX_HEIGHT;
                                                                }
                                                            }
                                                            canvas.width = width;
                                                            canvas.height = height;
                                                            var ctx = canvas.getContext("2d");
                                                            ctx.drawImage(img, 0, 0, width, height);

                                                            dataurl = canvas.toDataURL("image/jpeg", "0.1");
                                                            // dataurl = canvas.toDataURL("image/jpg").replace("image/jpg", "image/octet-stream");  
                                                            // above is the most important part because if you dont replace you will get a DOM 18 exception.
                                                            //alert('toggle issue here?')
                                                            //alert(dataurl)
                                                            // const file = new File([blob], 'fileName.jpg', {type:"image/png", lastModified:new Date()});
                                                            // alert(file)
                                                            // https://stackoverflow.com/questions/6850276/how-to-convert-dataurl-to-file-object-in-javascript/7261048#7261048
                                                            function srcToFile(src, fileName, mimeType){
                                                                return (fetch(src)
                                                                    .then(function(res){return res.arrayBuffer();})
                                                                    .then(function(buf){return new File([buf], fileName, {type:mimeType});})
                                                                );
                                                            }

                                                            srcToFile(
                                                                dataurl,
                                                                'instantgallery.jpg',
                                                                'image/jpeg'
                                                            )
                                                            .then(function(file){
                                                                //alert(file)
                                                                console.log(file);
                                                                newimagefile = file;
                                                               // alert(newimagefile)
                                                            })
                            }
                            img.src = evt.target.result;
                            //img.remove()
                            //lets resize image here:
                                                 
                                  
                            }

                            


            } else {
                file.value = null
                fileError.value = 'Please select an image file (png, jpg or webp)'
            }
        }


        // const config = {
        //         file: $image.files[0],
        //         maxSize: 500
        //     };
        //     const resizedImage = await resizeImage(config)
        //     console.log("upload resized image")

       

        function readURL(file){

        }

        // https://stackoverflow.com/questions/53708278/how-to-resize-the-image-in-vue

        // let myDropzone = new Dropzone("div#imageDrop");

      
        


        
    
        return { title, description, picurl, fileInputKey, showForm, fileError, handleChange, handleSubmit, isPending, isPending1, resizedImg, imageLoaded, addImageButton, maxLimitReached, premiumMaxLimitReached, closeForm, checkUserIsPremium, isPremium, premiumQueryReturned, createSubscription, show15LimitForm, show30LimitForm}

      
    }
}
</script>

<style scoped>
.add-image{
   
     opacity:0.95; 
    position: fixed;
    display:block; 
    /* width:90%;  */
    height:90px;
    /* background-color:#393c41;  */
    z-index:25;
    /* border-radius: 50%; */
     right:5%; 
    top:5%; 
    
}

/* #customform{
    width:90% !important;
} */
form{
    /* max-width: 95%; */
    text-align: left;
    padding: 10px;
    
}

#addImageButton{
  bottom:1%; right: 1%; z-index:5000; position: fixed; height: 80px; width: 80px;
  opacity:0.92;
   border: 2px solid #fff;
  padding: 1px;
  border-radius: 5%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 600px) {
    #addImageButton{
        bottom:1%; right: 1%; z-index:5000; position: fixed; height: 60px; width: 60px;
        opacity:0.92;
        border: 1px solid #fff;
        padding: 1px;
        border-radius: 50%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }

}

#addImageButton:hover{
  -o-transition:.25s;
  -ms-transition:.25s;
  -moz-transition:.25s;
  -webkit-transition:.25s;
  transition:.25s;
  box-shadow: 0 0 3px 3px #fff;
}


/* .navbuttons{
    opacity:0.5;
    display:block; 
    width:20px; 
    height:20px;
    background-color:#393c41; 
    z-index:20;
    border-radius: 50%;
     right:5%; 
    bottom:5%;
} */

/* .addImageButton{    
    opacity:0.5; 
    position:fixed; 
    display:block; 
    width:20px; 
    height:20px;
    background-color:#393c41; 
    z-index:25;
    border-radius: 50%;
     right:50%; 
    bottom:4%; 
} */


/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar1, #snackbar2 {
  visibility: hidden; /* Hidden by default. Visible on click */
  min-width: 250px; /* Set a default minimum width */
  margin-left: -125px; /* Divide value of min-width by 2 */
  background-color: #da0f41; /* Black background color */
  color: #fff; /* White text color */
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  padding: 16px; /* Padding */
  position: fixed; /* Sit on top of the screen */
  z-index: 2999 !important; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 70px; /* 30px from the bottom */
  font-family: 'Nunito', sans-serif !important;
    border-style: solid;
  border-color: white;
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar1.show, #snackbar2.show {
  visibility: visible; /* Show the snackbar */
   z-index: 2999 !important; /* Add a z-index if needed */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 70px; opacity: 1;}
}

@keyframes fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 70px; opacity: 1;}
}

@-webkit-keyframes fadeout {
  from {bottom: 70px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
  from {bottom: 70px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}




</style>