
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

// import { FieldValue } from 'firebase-admin'
// FieldValue = require('firebase-admin').firestore.FieldValue;



//import { arrayUnion } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAuysIR222tvkvrojYINH2G9RWxFSCvm_4",
    authDomain: "gallery-b37df.firebaseapp.com",
    projectId: "gallery-b37df",
    storageBucket: "gallery-b37df.appspot.com",
    messagingSenderId: "552529199407",
    appId: "1:552529199407:web:adb815276d3535d414a1da"
  };


  //init firebase
  firebase.initializeApp(firebaseConfig)

  //init services
  const projectFirestore = firebase.firestore()
  // const arrayUnion = firebase.firestore()
  const FieldValue = firebase.firestore.FieldValue
  // const updateDoc = firebase.firestore()
  const projectAuth = firebase.auth()
  const projectStorage = firebase.storage()

  const deleteUser = firebase.auth()

  //timestamp
  const timestamp = firebase.firestore.FieldValue.serverTimestamp

  export { projectFirestore, projectAuth, timestamp, projectStorage, FieldValue, firebase, deleteUser}
